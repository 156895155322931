<template lang='pug'>
  include ../../mixins
  ui-accordion(
    :init='false',
    :full-height='true'
  )
    template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
      +b.ns-accordion(
        :class='{"ns-accordion--is-open": activeState}',
      )
        ui-click-outside(
          :do='collapse'
        )
          div
            +e.question(
              @click='toggleActive()', 
              v-if='!isDesktop'
            )
              +e.icon.mdi.I(:class='icon')
              +e.text
                +b.ds-caption--size_sm.--size_xs-xl.--medium.--appearance_center.P {{ _('Фільтри') }}  

            +e.description(
              :style='{maxHeight: myHeight}',
              :class='{"isFullMaxHeight": isDesktop}'
            )
              +e.content--no-pa

                filter-ui(
                  :disabled="loading"
                  :value="prepared"
                  :available="availableMap"
                  :filters="filters"
                  @input="handleInputFilter"
                )

                  template(v-slot:append='')
                    cell(cols="12")
                      +b.ds-panel--space_xl
                        +e.element--offset_full
                          +b.ns-button.A.ns-space_mt--5(
                            href=''
                            @click.prevent='handleInputFilter({})',
                          )
                            +e.title.P  {{ _("Відмінити фільтри") }}
</template>

<script>

import isShallowEqual from '@wordpress/is-shallow-equal'
import FilterUi from './Ui'
export default {
  props: [
    'loading', 'value', 'receive', 'all', 'available', 'receiveAvailable',
    'displayed',
  ],
  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)
        // this.receiveAvailable({ filters })
      },
    },
  },

  created() {
    this.receiveAvailable({})
    this.receive()
  },

  computed: {
    filters() {
      return this.all
    },
    availableMap() {
      if (!this.available) {
        return {}
      }
      return this.available.reduce((acc, x) => {
        acc[x] = x
        return acc
      }, {})
    }
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },
    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },
    handleInput(value) {
      this.prepare(value)
      this.receiveAvailable({ filters: this.prepared })
    },
    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },
  },
}

</script>
