<template lang="pug">
  include ../../mixins
  +b.g-row--appearance_column
    +b.g-cell.g-cols--12(v-if='!authUser')
      +b.g-row--align_center.--justify_center.ns-space_mb-xl--10
        +b.g-cell.g-cols--12.--4-xl
          +b.ns-button--light.A(
            href='',
            @click.prevent='getUserInfo(false)'
            :class='{"is-active": !isOldUser}'
          ) 
            +e.title.P.ds-caption--size_lg {{ _('Я новий користувач') }}
        +b.g-cell.g-cols--12.--4-xl
          +b.ns-button--light.A(
            href='',
            @click.prevent='getUserInfo(true)'
            :class='{"is-active": isOldUser}'
          )
            +e.title.P.ds-caption--size_lg {{ _('Я зареєстрований') }}

    +b.g-cell.g-cols--12
      +b.ds-panel--space_lg.--space_10-xl.relative.bg-white
        +e.element--offset_full
          +b.g-row--justify_between-xl
            +b.g-cell.g-cols--12.--5-xl(v-if='isOldUser && !isAuth')
              +b.ds-caption--size_2md.--medium.P {{ _('Щоб продовжити увійдіть, будь-ласка, в особистий кабінет') }}
              login(
                :no-redirect='true'
              )
            +b.g-cell.g-cols--12.--5-xl(v-else)
              ui-loader(v-if='isLoad')
              +b.VALIDATION-OBSERVER(
                ref="validator",
                v-slot="{ valid }",
                tag="form",
              )
                order-personal(
                  @setData='saveFields($event)'
                  :user='user'
                )
                order-delivery(
                  @setData='saveFields($event)'
                  :user='user'
                )
                order-additional(
                  @setData='saveFields($event)'
                  :user='user'
                )
                +b.ns-button.BUTTON.ns-space_mt--10(
                  type='submit'
                  :disabled='isLoad',
                  @click.prevent='prepareData(valid)'
                )
                  +e.title.P {{ _('Оформити') }}  
                +b.ds-caption.--color_grey.--size_sm.--regular.P.ns-space_mt--10.ns-space_mb--10  {{ _("* поля, обов'язкові для заповнення") }}
                validation-provider(
                  :rules='{ required: { allowFalse: false } }', 
                  v-slot="{ errors }"
                  name='agree_rules'
                )
                  +b.d-control-input--appearance_clear.--appearance_flexible
                    +e.element.INPUT(
                      type='checkbox'
                      :value='true',
                      name='accept_rules'
                      v-model='accept_rules'
                      id='`accept_rules'
                    )
                    +e.LABEL.label--static(
                      for='accept_rules'
                    ) 
                      +b.ds-caption.--size_xs.P {{ _('Я виражаю згоду на обробку моїх персональних даних, погоджуюся з') }} #[+b.ns-link--inline.--accent.A.ds-caption--size_xs(:href='offer', target='_blank') {{ _('політикою конфіденційності') }} ] #[span {{ _('та') }} ] #[+b.ns-link--inline.--accent.A.ds-caption--size_xs(:href='rules', target='_blank') {{ _('умовами вступу') }} ] #[span {{ _('в Nutricia Club!') }} ]
                    +b.error-label--top.LABEL(v-if="errors.length") {{ errors[0] }}
            +b.g-cell.g-cols--12.--6-xl 
              order-cart(
                :error='local_error'
              )

</template>

<script>
import pathOr from 'ramda/src/pathOr'
import { mapState, mapActions } from 'vuex'
import OrderPersonal from '@app/Order/Personal'
import OrderDelivery from '@app/Order/Delivery'
import OrderAdditional from '@app/Order/Additional'
import OrderCart from '@app/Order/Cart'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import Login from '@app/Forms/Login'

import { 
  AnswerMessage,
} from '@utils/submit'

import { OrderCreate } from '@api/order.service'
import { userInfo } from '@api/auth.service'

import analyticCheckout, { analyticPurchase } from '@analytic/checkout'

export default {
  name: 'OrderView',
  components: {
    OrderPersonal,
    OrderDelivery,
    OrderAdditional,
    OrderCart,
    Login,
  },
  mixins: [ FormMixin ],
  data() {
    return {
      authUser: window.authent,
      isLoad: false,
      offer: window.offer,
      rules: window.rules,
      order: {},
      isAuth: false,
      isOldUser: false,
      user: '',
      accept_rules: true,
      local_error: ''
    }
  },
  computed: {
    ...mapState('cart', [
      'cart'
    ])
  },
  async mounted() {
    this.getUserInfo(false)
    await this.getCart()

    
  },
  methods: {
    ...mapActions('cart', [
      'getCart'
    ]),
    sendAnalytic(step) {
      analyticCheckout(this.cart.groups, step)
    },
    async getUserInfo(value) {
      this.isOldUser = value
      try {
        let { data: { props } } = (await userInfo.execute())
        this.isAuth = true
        this.isOldUser = true
        this.user = props
      } catch (e) {
        this.isAuth = false
      }
    },
    async prepareData(valid) {
      this.local_error = ''
      this.$refs.validator.validate()
      if (!valid) return
      this.order.pharmacy = pathOr('', ['id'], this.order.pharmacy)
      this.order.city = pathOr('', ['id'], this.order.city)

      this.submit(valid, this.order)
    },
    send(data) {
      return OrderCreate.execute({}, data)
        .then((res) => {
          analyticPurchase(this.cart.groups)
          AnswerMessage(res.meta, this)
          this.$emit('close')
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors, body) {
      const parsed = defaultValidatorErrorsParser(errors)
      console.log(body, '%%%%%%%%%%%%%%');
      if (body[0] && body[0].message) {
        let err = {
          message: {
            header: this._('Невозможно оформить заказ'),
            text: body[0].message,
          },
        }
        AnswerMessage(err, this)
        this.local_error = body[0].message
      }
      this.$refs.validator.setErrors(parsed)
    },
    saveFields(event, step) {
      this.order[event.key] = event.value
    }
  }
}
</script>