<template lang="pug">
  include ../../mixins 
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    .relative
      +b.error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    +b.g-row
      +b.g-cell.g-cols--12.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required'",
          :input-label='labels.name'
          v-model='form.name'
          id='name',
          name='name',
        )  
      +b.g-cell.g-cols--12.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required|email'",
          :input-label='labels.email'
          v-model='form.email'
          id='email',
          ref='email',
          name='email',
        )  
      +b.g-cell.g-cols--12.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.phone'
          id='phone',
          ref='phone'
          name='phone',
        )  
      +b.g-cell.g-cols--12.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required'",
          :input-label="labels.password"
          v-model='form.password1'
          id='password1',
          autocomplete='new-password'
          type='password',
          name='password1',
        )
      +b.g-cell.g-cols--12.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required|confirmed:password1'",
          :input-label="labels.password2"
          v-model='form.password2'
          id='password2',
          autocomplete='new-password2'
          type='password',
          name='password2',
        )
    +b.g-row 
      +b.g-cell.g-cols--12.ns-space_mt--10 
        validation-provider(
          :rules='{ required: { allowFalse: false } }', 
          v-slot="{ errors }"
          name='agree_rules'
        )
          +b.d-control-input--appearance_clear.--appearance_flexible
            +e.element.INPUT(
              type='checkbox'
              :value='true',
              name='accept_rules'
              v-model='accept_rules'
              id='`accept_rules'
            )
            +e.LABEL.label--static(
              for='accept_rules'
            ) 
              +b.ds-caption.--size_xs.P {{ _('Я виражаю згоду на обробку моїх персональних даних, погоджуюся з') }} #[+b.ns-link--inline.--accent.A.ds-caption--size_xs(:href='offer', target='_blank') {{ _('політикою конфіденційності') }} ] #[span {{ _('та') }} ] #[+b.ns-link--inline.--accent.A.ds-caption--size_xs(:href='rules', target='_blank') {{ _('умовами вступу') }} ] #[span {{ _('в Nutricia Club!') }} ]
            +b.error-label--top.LABEL(v-if="errors.length") {{ errors[0] }}  
    +b.g-row 
      +b.g-cell.g-cols--12.ns-space_mt--10 
        +b.ns-button.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('Зареєструватись') }}
    +b.g-row--appearance_nowrap-till-xl.--justify_center
      +b.g-cell.g-cols.--12-xl.ns-space_mt--9
        +b.ds-caption--size-xs.--appearance_center-xl.P {{ _('Зареєструватись за допомогою соціальних мереж:') }}
      +b.g-cell.g-cols.ns-space_mt--9.ns-space_mt-xl--7
        +b.facebook-link.A(
          href='/oauth/login/facebook/'
        )
          +e.img(src='/static/img/facebook.png')


</template>
<script>
import { registerResource } from '@api/auth.service'
import { 
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { masking } from '@utils/helpers'
export default {
  name: 'Registration',
  mixins: [ FormMixin ],
  data() {
    return {
      labels: {
        name: this._('Ваше ім‘я'),
        email: this._('Email'),
        password: this._('Password'),
        password2: this._('Password repeat'),
        phone: this._('Телефон')
      },
      form: {
        email: '',
        password1: '',
        phone: '',
        name: '',
        first_name: '',
        last_name: '',
      },
      accept_rules: true,
      offer: window.offer,
      rules: window.rules,
      isLoad: false,
      non_field_errors: '',
    }
  },
  mounted() {
    masking('phone', this)
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.form.first_name = ''
      this.form.last_name = ''
      let name = this.form.name.split(' ')
      this.form.first_name = name[0]
      this.form.last_name = name[1]
      this.submit(valid, this.form)
    },
    send(data) {
      return registerResource.execute({}, data)
        .then((res) => {
          AnswerMessage(res.meta, this)
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  }
}
</script>