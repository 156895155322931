import Login from '@app/Forms/Login'
import Logout from '@app/Forms/Logout'
import PasswordRecovery from '@app/Forms/PasswordRecovery'
import PasswordRecoveryConfirm from '@app/Forms/PasswordRecoveryConfirm'
import Registration from '@app/Forms/Registration'

export default function install(Vue) {
  Vue.component(Login.name, Login)
  Vue.component(Logout.name, Logout)
  Vue.component(PasswordRecovery.name, PasswordRecovery)
  Vue.component(PasswordRecoveryConfirm.name, PasswordRecoveryConfirm)
  Vue.component(Registration.name, Registration)
}

