<template lang='pug'>
  include ../../mixins
  row(
    appearance="spaced"
  )
    slot(
      name="prepend", 
      cols="12"
    )
    cell.g-cols--spaced_vert-none(
      cols="12"
      v-for="filter in baseFilters"
      :key="filter.attribute.id"
    )
      ui-accordion(
        :init='value[filter.attribute.label] || filter.is_expand'
      )
        template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
          +b.ns-accordion(
            :class='{"ns-accordion--is-open": activeState }',
          )
            +e.question--spaced_bottom-none(
              @click='toggleActive()',
              v-if='!filter.is_delimiter'
            )
              +e.icon.mdi.I(:class='icon')
              +e.text
                +b.ds-caption--size_sm.--bold.SPAN  {{ filter.attribute.title }}
            +e.questionb--spaced_bottom-none(v-else)
              +e.text
                +b.ds-caption--size_m.--bold.--color_accent.SPAN  {{ filter.attribute.title }}
            +e.description--spaced(
              :style='{maxHeight: myHeight}',
            )
              tag(
                :tag="$options.widgetsMap[filter.type]"
                :available="available"
                :parameters="parameters"
                :value="value[filter.attribute.label]"
                :disabled="disabled"
                :filter="filter"
                @input="updateValue(filter.attribute.label, $event)"
                :size="['md', 'lg-lg']"
              )
    slot(
      name="append", 
      cols="12"
    )
</template>

<script>

import SelectWidget from './Widgets/SelectWidget'
import CheckboxWidget from './Widgets/CheckboxWidget'
import SliderWidget from './Widgets/SliderWidget'
import DatePickerWidget from './Widgets/DatePickerWidget'
import CombinedInputWidget from './Widgets/CombinedInputWidget'
import AutocompleteMultiselectWidget from './Widgets/AutocompleteMultiselectWidget'

const WIDGETS_MAP = {
  'int-range': SliderWidget,
  'date-range': DatePickerWidget,
  select: SelectWidget,
  multiselect: CheckboxWidget,
  'combined-input': CombinedInputWidget,
  'autocomplete-multiselect': AutocompleteMultiselectWidget,
}

export default {
  components: {},
  widgetsMap: WIDGETS_MAP,
  props: [
    'disabled', 'value', 'filters', 'available',
  ],
  data() {
    return {
      search: ''
    }
  },
  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
        
      }
    },

    baseFilters() {
      return this.getAvailableFilters(this.filters)
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if ('search/SET_SEARCH' == mutation.type ) { 
        this.search = mutation.payload
        this.updateValue('search', this.search)
      }
    })
  },
  methods: {
    getAvailableFilters(filters = []) {
      return filters
      // .filter(filter => filter.type in this.$options.widgetsMap)
    },
    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}

</script>
