<template lang="pug">

  include ../../../mixins.pug
  +b.ds-panel--space_xl
    +e.element--offset_full

      +b('ul').pagination(v-if='page && range.max > 1')
        +e.LI.item: +e.A.link.--prev(
          href='#', 
          @click.prevent='previous()'
          :class='{"is-disabled": !page.hasPrevious()}'
        ).mdi.mdi-chevron-left
        template(v-if='page.hasPrevious()')
          +e.LI.item: +e.A.link(href='#', @click.prevent='select(range.min)') {{ range.min }}
        +e.LI.item(v-if='gaps.left'): +e.link ...
        +e.LI.item(v-for='page in fills.left'): +e.A.link(href='#', @click.prevent='select(page)') {{ page }}
        +e.LI.item: +e.link.is-active(v-text='page.getPageNumber()')
        +e.LI.item(v-for='page in fills.right'): +e.A.link(href='#', @click.prevent='select(page)') {{ page }}
        +e.LI.item(v-if='gaps.right'): +e.link ...
        template(v-if='page.hasNext()')
          +e.LI.item: +e.A.link(href='#', @click.prevent='select(range.max)') {{ range.max }}
        +e.LI.item: +e.A.link.--next(
          href='#', 
          @click.prevent='next()'
          :class='{"is-disabled": !page.hasNext()}'
        ).mdi.mdi-chevron-right

</template>

<script>

import { LimitOffsetPagination } from './Pagination'
export default {
  template: '<div></div>',
  name: 'UiLimitPagination',
  props: {
    pagination: {},
    extra: {
      type: Number,
      default: 2
    },
  },

  data() {
    return {
      page: {},
      range: {
        min: 0,
        max: Infinity
      },
      gaps: {
        left: false,
        right: false
      },
      fills: {
        left: [],
        right: []
      },
      one: 1,
      zero: 0
    }
  },

  created() {
    if (this.page) this.recalcRange()
  },

  watch: {
    pagination: {
      deep: true,
      immediate: true,
      handler() {
        this.page = new LimitOffsetPagination(this.pagination)
      }
    },
    page(nval, oval) { this.recalcRange() },
  },

  methods: {
    recalcRange() {
      let [min, max] = this.page.getPageRange()
      let current = this.page.getPageNumber()
      let [start, end] = this.page.getPageRange(this.extra, current)
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      let range = Array
        .apply(null, { length: end - start + this.one })
        .map((x, i) => i + start)
      let currentIndex = range.indexOf(current)
      if (currentIndex !== -this.one) {
        this.fills.left = range.slice(this.zero, currentIndex)
        let minIndex = this.fills.left.indexOf(min)
        if (minIndex >= this.zero) {
          this.fills.left.splice(minIndex, this.one)
        }
        this.fills.right = range.slice(currentIndex + this.one)
        let maxIndex = this.fills.right.indexOf(max)
        if (maxIndex >= this.zero) {
          this.fills.right.splice(maxIndex, this.one)
        }
      } else {
        this.fills.left = this.fills.right = []
      }

      this.gaps.left = (
        this.fills.left.length &&
        this.fills.left[0] !== min + this.one
      )
      this.gaps.right = (
        this.fills.right.length &&
        this.fills.right[this.fills.right.length-this.one] !== max - this.one
      )
      this.range.min = min
      this.range.max = max
    },

    select(number) {
      this.$emit('input', this.page.getParameters(number))
    },

    next() {
      this.$emit('input', this.page.next())
    },

    previous() {
      this.$emit('input', this.page.previous())
    },
  }
}

</script>