<template lang="pug">
  include ../../mixins  
  +b.preloader--withOpacity 
    +b.loader
      +e.img.IMG(src='/static/img/logo.svg')
</template>

<script>
export default {
  name: 'UiLoader'
}
</script>