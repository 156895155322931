<script>
import { confirmWithPromise } from '@utils/helpers'
import { logoutResource } from '@api/auth.service'
export default {
  name: 'Logout',

  methods: {
    async logout() {
      let url = window.baseUrl
      let question = this._('Ви вже йдете?')
      await confirmWithPromise(question, this)
      await logoutResource.execute().then(() => window.location = url)
    } 
  },
  render(h) {
    return this.$scopedSlots.default({
      open: () => {
        this.logout()
      }
    })
  }
}
</script>