import compileProducts from '@analytic/utils'
export default function analyticCheckout(products, step) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []

  let objects_list = compileProducts(products)

  dataLayer.push({
    'ecommerce': {
      'currencyCode': 'UAH',
      'checkout': {
        'actionField': {
          'step': step
        },
        'products': objects_list
      }
    },
    'event': 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': `Checkout Step ${step}`,
    'gtm-ee-event-non-interaction': 'False',   
   
  })
}

export function analyticInitCheckout(products) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []
  let objects_list = compileProducts(products)
  let ids = objects_list.map(el => el.id)
  let items = ids.map(el => {
    return {
      id: el,
      'google_business_vertical': 'retail'
    }
  })
  let contents = objects_list.map(el => {
    return {
      id: el.id,
      quantity: el.quantity
    }
  })
  dataLayer.push({
    'event': 'InitiateCheckout',
    'value': window.cart_total,
    'currency': 'UAH',
    'content_category': '',
    'content_ids': ids,
    'items': items,
    'contents': contents
  })
}

export function analyticPurchase(products) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []
  let objects_list = compileProducts(products)
  let ids = objects_list.map(el => el.id)
  let items = ids.map(el => {
    return {
      id: el,
      'google_business_vertical': 'retail'
    }
  })
  let contents = objects_list.map(el => {
    return {
      id: el.id,
      quantity: el.quantity
    }
  })
  dataLayer.push({
    'event': 'InitiateCheckout',
    'value': window.cart_total,
    'currency': 'UAH',
    'content_category': '',
    'content_ids': ids,
    'items': items,
    'contents': contents
  })
}

