
/**
 * return tree view , 
 * set 4 spaces before initital string if it has depth
 * @param {string} string - text in select label
 * @param {number} by - depth
 */
export function outstand(string, by) {
  if (by < one) {
    return string
  }
  
  const whitespace = '\u00A0'.repeat(four)
  const marker = ''
  // const marker = '\uFE42'
  // const marker = '\u232A'

  return whitespace.repeat(by) + (by > zero ? marker : '') + string
}


import Confirm from '@app/UiElements/Confirm'

export function confirmWithPromise(question, $this) {
  return new Promise((res, rej) => {
    let pr = arg => {
      return new Promise((resolve, reject) => {
        if (true == arg) {
          resolve(arg)
        } else {
          reject( new Error('...'))
        }
      }).then((result) => {
        res(result)
      }, err => {
        rej()
      })
    }
    $this.$modal.show(
      Confirm,
      {
        question: question,
        promise: pr
      }, {
        height: 'auto',
        width: '300px',
        adaptive: true
      })
  })
}


/**
 * @param {string} path - name of key(
 *  f.e. Object is -{key: {child_key: 'test'}}, path - key, child_key
 * )
 */
export function pathGetter(path) {
  if (is.not.array(path)) {
    path = Array.from(arguments)
  }

  return item => path.reduce((acc, x) => acc ? acc[x] ? acc[x] : '-' : '-', item)
}



// import Vue from 'vue'
import Inputmask from "inputmask/dist/inputmask.min"


export function mask(inputObject, number) {
  if (inputObject.selectionStart) {
    inputObject.setSelectionRange(number, number)
    inputObject.focus()
  }
  // danone regex
  // /^[0-9+]{1,}[0-9-()]{3,15}$/
  let mask = new Inputmask({ mask: "+38(099)999-9999", greedy: false})
  mask.mask(inputObject)
}
export function masking(name, $this) {
  let zero = 0
  let reg = /\d+/
  let events = ['click', 'focus', 'keyup']
  events.forEach(event => {
    $this.$refs[name].$el.addEventListener(event, e => {
      let numb = e.target.value.match(reg)
      if (numb) {
        mask(e.target, numb[zero].length)
      } else {
        mask(e.target, zero)
      }
    })
  })

}


