// import { createRouter } from 'src/router'
import { mapState, mapActions } from 'vuex'

import { createI18n } from './i18n'
import { createStoreObject } from '@store'
export function createApp({ Vue, Router, Store, I18n }) {
  // const router = createRouter({ Vue, Router })
  const i18n = createI18n({ Vue, I18n })
  const store = createStoreObject({ Vue, Store })
  const app = new Vue({
    i18n,
    store,
    el: '#app',
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false, 
        None: undefined,
        limit: window.availability_limit,
      }
    },
    computed: {
      ...mapState('search', [
        'show_overlay'
      ])
    },
    methods: {
      ...mapActions('search', [
        'trigger_overlay'
      ])
    }
  })

  return { app, store, i18n }
}

// export {
//   createRouter,
// }
