<template lang="pug">
  include ../../mixins
  +b.g-cell.g-cols--12.ns-space_mt--3.ns-space_mb--3(
    v-if='Number(limit) >= maxCount'
  )
    slot
    //+b.ds-caption--size_xs.--regular.--color_red.P(v-if='product.base.element.props.quantity == product.base.quantity') {{ _('Товар можна замовити лише у кількості')}} {{ product.base.element.props.quantity }} {{ _("шт") }}
   
  
</template>

<script>
export default {
  name: 'ProductAvailability',
  props: ['maxCount', 'amount', 'quantity'],
  data() {
    return {
      limit: window.availability_limit,
      label: this._('Максимальна кількість одиниць для замовлення')
    }
  }
}
</script>