<script>
const BASE_AMOUNT = 1
export default {
  name: 'CounterController',
  data() {
    return {
      amount: 1
    }
  },
  methods: {
    changeAmount(val) {
      this.amount = val.count
    },
    clear(val) {
      this.amount = BASE_AMOUNT
    }
  },
  render(h) {
    return this.$scopedSlots.default({
      amount: this.amount,
      change: val => this.changeAmount(val),
      clear: () => this.clear()
    })
  }
}
</script>