<template lang='pug'>
  include ../../mixins
  ui-click-outside(:do='hide')
    +b.ds-panel--space_xs.--space_md-xl
      +e.element--offset_full
        slot(
          name='inputview',
          :keyup-event='dynamicSearch'
          :keydown-event='getFocus',
          :go-to-search='goToSearch'
        )
        +b.seacrh-result-wrapper
          ui-loader.mini-loading(v-if='load')
          transition-group(name="slidedown")
            
            +b.search-results(
              v-if='results.length', 
              key='results'
            )
              +b.ds-panel--space_xs.--space_md-xl
                +e.element--offset_full
                  +b.g-row
                    +b.g-cell.g-cols
                      +b.A.default-text.link-call.link-call--d-flex(
                        href="",
                        @click.prevent='goToSearch'
                      )
                        +b.ns-link--accent.A.ds-caption--size_xs.--size_md-xl {{ _('Найдено') }} {{ meta.total }}
                  +b.list-search
                    +b.g-row
                      +b.g-cell.g-cols--12(
                        v-for='link in results'
                      )
                        +b.ns-link.A.ds-caption--size_xs.--size_sm-xl(
                          :href='link.props.url'
                          v-html='find_value_and_highlight_term(link.caption, link.caption, localsearch)'
                        ) 
            +b.search-results(
              v-if='meta.total == 0',
              key='withoutresults'
            )
              +b.ds-panel--space_xs.--space_sm-xl(
                
              )
                +e.element--offset_full
                  +b.ds-caption--size_6.--regular.P(
                    v-if='meta.total == 0',
                  ) {{ _("По вашему поисковому запросу ничего не найдено.") }}                  

</template>
<script>
// Highlight the query part of the search term
function highlight_term(value, term) {
  return value
    .replace(new RegExp(`(?![^&;]+;)(?!<[^<>]*)(${term})(?![^<>]*>)(?![^&;]+;)`, "gi"),
      "<b>$1</b>")
}

function find_value_and_highlight_term(template, value, term) {
  return template
    .replace(new RegExp(`(?![^&;]+;)(?!<[^<>]*)(${value})(?![^<>]*>)(?![^&;]+;)`, "gi"), 
      highlight_term(value, term))
}
import { searchResource } from '@api/vendor.service'
import UiClickOutside from '@app/UiElements/UiClickOutside'
import { mapMutations, mapActions, mapState } from 'vuex'
import analyticSearchResults from '@analytic/search_results'
export default {
  name: 'SearchView',
  props: ['className', 'fieldId', 'url', 'localsearch'],
  components: {
    UiClickOutside
  },
  data() {
    return {
      // localsearch: '',
      show: false,
      arrowCounter: -1,
      empty: false,
      load: false,
      results: [],
      length: 1,
      meta: {
        
      }
    }
  },

  computed: {
    ...mapState('cart', [
      'cart_info'
    ])
  },
  methods: {
    find_value_and_highlight_term,
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    ...mapActions('search', [
      'trigger_overlay'
    ]),
    hide() {
      this.reset()
      this.activeClass = false
    },
    reset() {
      this.results = []
      this.clearClas = false
      this.empty = false
      this.activeClass = false
      this.arrowCounter = -1
      this.meta = {}
    },
    activeSearch() {
      this.show = !this.show
      
      
    },
    clearSearch() {
      this.search = ''
    },
    showSearch() {
      if ('new-header-mobile-search' == this.className) {
        this.show = true
      } 
    },
    closeSearch(e) {
      if ('new-header-mobile-search' == this.className) {
        this.show = false
      } 
    },
    dynamicSearch(event) {
      if ('Enter' == event.code) { 
        
        this.goToSearch()
        return
      }
      let up = 38
      let down = 40
      let width = 1200
      if (window.innerWidth < width) {
        this.localsearch = event.target.value
      }
      if (up !== event.keyCode && down !== event.keyCode) {
        this.load = true 

        searchResource.execute({search: this.localsearch}).then(({ data }) => {
          this.trigger_overlay(true)
          this.results = data.items
          this.meta = data.pagination
          this.load = false
          this.empty = false
          analyticSearchResults(
            this.cart_info.total_price, 
            this.localsearch, 
            this.results
          )
        })

      } else {
        this.results = []
        this.empty = false
      }
    },
    goToSearch() {
      this.trigger_overlay(false)
      if (window.is_main_page) {
        this.SET_SEARCH(this.localsearch)
      } else {
        window.location.pathname = `${window.indexPage}filters/search=${this.localsearch}/`
      }
      this.reset()

    },
    onEnter() {
      this.arrowCounter = -1
      this.goToSearch()
    },
    getFocus(event) {
      if ( 'Enter' == event.code) { 
        this.reset()
        return
      }
      let i = 0
      let one = 1
      let zero = 0
      if (this.results.length > i) {
        if ('ArrowDown' === event.code) {
          this.$refs['search-link'][i].focus()
        }
        document.addEventListener('keydown', e => {
          if ('ArrowDown' === e.code && this.arrowCounter < this.results.length - one) {
            e.preventDefault()
            this.arrowCounter = this.arrowCounter + one
            this.$refs['search-link'][this.arrowCounter].focus()
          }
          if ('ArrowUp' === e.code && this.arrowCounter > zero) {
            e.preventDefault()
            this.arrowCounter = this.arrowCounter - one
            this.$refs['search-link'][this.arrowCounter].focus()
          }
        })
      }
    },
  },
}

</script>
