<template lang='pug'>
  resource-loader-controller(
    :resource="$options.attributesResource"
    v-slot="allProps"
  )
  
    resource-loader-controller(
      :resource="$options.availableAttributesResource"
      v-slot="availableProps"
    )
      div
        search-filter(
          :value="filters"
          @input="changeFilters"
          :allProps="allProps"
          :availableProps="availableProps"
        )
</template>

<script>

import { attributesResource, availableAttributesResource } from '@api/catalog.service'

import SearchFilter from '@app/Filters'

export default {
  components: { SearchFilter },
  props: [
    'space', 'filters', 'pagination',
  ],
  attributesResource,
  availableAttributesResource,
  methods: {
    changeFilters(value) {
      this.$emit('update:filters', value)
    },
  },
}

</script>
