<template lang="pug">
  include ../../../mixins
  controller(
    :localsearch='search'
  )
    template(#inputview='{ keydownEvent, keyupEvent, model }')
      +b.search-wrapper(
        :class='{"is-active": show}'
      )
        +e.icon.icon-search.d-theme-base-relief__text--4.SPAN(
          @click='trigger'
        )
        +e.field(
          v-if='show',
          :class='{"is-active": show}'
        )
          control-simple-input(
            type='search',
            :placeholder='_("Пошук продукту")',
            v-model='search',
            @keyup="keyupEvent",
            @keydown="keydownEvent",
          )


</template>
<script>
import Controller from '@app/Search/Controller'
export default {
  name: 'UiSearch',
  components: {
    Controller
  },
  data() {
    return {
      search: '',
      show: true,

    }
  },
  methods: {
    letsSearch() {

    },
    trigger() {
      // this.show = !this.show
    }
  }
}
</script>