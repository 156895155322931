
export const DICTIONARY = {
  en: {
    "messages": {
      "required": "This field is required",
      "phone": "Enter valid phone",
      "email": "Enter valid e-mail",
      "decimal": "Enter the correct number",
      'alpha': 'Only letters',
      'alpha_spaces': 'Only letters',
      'min': (e, a) => ` Enter at least ${a.length} characters`,
      'min_value': (e, a) => `Value can not be low then ${a.min}`,
      'max': 'Value is too large',
      'confirmed': "The password confirmation does not match"
    }
  },
  uk: {
    "messages": {
      "required": "Поле обов`язкове до заповнення",
      "phone": "Будь ласка введiть валiдний номер телефону",
      "email": "Будь ласка введiть валiдний адрес пошти",
      "decimal": "Введiть коректне число",
      'alpha': 'Тiльки лiтери',
      'alpha_spaces': 'Тiльки лiтери',
      'min': (e, a) => `Введiть мiнiмум ${a.length} символи `,
      'min_value': (e, a) => `Введiть мiнiмум ${a.min} символи `,
      'max': 'Введене значення задовге',
      'confirmed': "Введені паролі не співпадають"
    }
  },
  ru: {
    "messages": {
      "required": "Обязательное поле",
      "phone": "Введите валидный номер телефона",
      "email": "Введите валидный email",
      "decimal": "Введите корректное число",
      'alpha': 'Только буквы',
      'alpha_spaces': 'Только буквы',
      'min': (e, a) => `Введите минимум ${a.length} символа `,
      'max': 'Введенное значение превышает лимит',
      'confirmed': "Введенные пароли не совпадают"
    }
  },
  de: {
    "messages": {
      "required": "Dieses Feld ist erforderlich",
      "phone": "Geben Sie eine gültige nummer",
      "email": "Geben Sie eine gültige E-Mail-Adresse ein",
      "decimal": "Geben Sie die richtige Nummer ein",
      'min_value': (e, a) => `Value can not be low then ${a.min} `,
      "max_value": "Geben Sie die richtige Nummer ein",
      'alpha': 'Nur Buchstaben'
    }
  },
}

