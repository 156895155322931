// import { pick } from 'ramda'
import { renderSlim } from '@aspectus/vue-utils'
import template from 'url-template'
var filterUrl = template.parse('/{;filters*}/')

const same = x => x

import { checkLogo } from '@utils/ui-scripts'
export default {
  name: 'router-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
  },

  data() {
    return {
      parameters: this.initial,
      friendlyUrl: {
        filters: {}
      }
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(value, oldValue) {
        
        this.parameters = this.from({
          ...this.initial,
        })

      },
    },
  },

  methods: {
    updateUrl(parameters) {
      const query = this.to(parameters)
      let zero = 0
      this.friendlyUrl.filters = query.filters
      let keys = Object.keys(this.friendlyUrl.filters)
      for (let x=0; x<= keys.length; x++ ) {
        if (this.friendlyUrl.filters[keys[x]] != undefined) {
          if ( zero == this.friendlyUrl.filters[keys[x]].length ) {
            delete this.friendlyUrl.filters[keys[x]]
          }
        }
      }
      let url = filterUrl.expand(this.friendlyUrl)
      url = url.replace(/;/, '')
      url = url.replace(/%2C/g, ',')
      let length = 2
      let fullUrl
      let shift = 1
      let page = Math
        .floor(parameters.offset / parameters.limit) + shift
      if (url.length > length) {
        fullUrl = `${window.baseUrl}filters${url}`
      } else {
        fullUrl =`${window.baseUrl}`
      }
      if (page > shift) {
        fullUrl = `${fullUrl}page/${page}/`
      }
      history.pushState({'url': fullUrl}, '', `${fullUrl}${window.location.search}`)
      checkLogo()
    },

    changeParameters(value) {
      this.parameters = value
      this.updateUrl(this.parameters)
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      parameters: this.parameters,
      changeParameters: this.changeParameters,
      updateUrl: this.updateUrl,
    }), h, 'tag')
  },
}
