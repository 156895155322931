<template lang="pug">
  include ../../mixins 
  +b.order-cart-view
    +b.ds-caption--size_md.--medium.H2 {{ _('Товарів у кошику') }} {{ cart.quantity }} {{ _(', на сумму:') }} {{ cart.total_price }} {{ _('грн') }}
    template(v-if='cart.quantity')
      +e.element.CART-PRODUCT(
        v-for='(product, idx) in cart.groups'
        :key='idx',
        :product='product',
      )
        template(
          #default='{ removeFromCart, updateCart }'
        )
          order-product-ui(
            :product='product',
            @removeFromCart='removeFromCart',
            @updateCart='updateCart',
          )
    template(v-else)
      +b.ds-caption--size_sm.--bold.P.ns-space_mt--5 {{ _('Для оформлення заявки Вам потрібно додати до кошика мінімум 1 товар.') }} 

</template>
<script>
import { mapState, mapActions } from 'vuex'
import OrderProductUi from '@app/Order/ProductUi'
import CartProduct from '@app/Cart/Product'
export default {
  naem: 'OrderCart',
  components: {
    CartProduct,
    OrderProductUi,
  },
  async mounted() {
    await this.getCart()
  },
  computed: {
    ...mapState('cart', [
      'cart'
    ])
  },
  watch: {
    cart() {
      if (!this.cart.quantity) window.location = window.baseUrl
      
    }
  },
  methods: {
    ...mapActions('cart', [
      'getCart'
    ]),
  }
}
</script>