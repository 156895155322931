<template lang="pug">
  include ../../mixins
  +b.MODAL-TRIGGER(
    url='Cart/View',
    :classes='["extralarge-modal"]'
    name='CartView'
  )
    template(#default='{ open }')
      +b.cart-preview.g-row--appearance_column.--align_center(@click='open()')
        +e.icon.IMG(
          src='/static/svg/uEA03-shopping-cart.svg'
        )
        +e.counter.SPAN.d-theme-base-content-entity--color_accent {{ cart_info.quantity || 0 }}
        +e.text.ds-caption--size_2xs.is-visible-xl.P {{ _('Кошик') }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'CartPreview',
  data() {
    return {

    }
  },
  computed: {
    ...mapState('cart', [
      'cart_info'
    ])
  },
  created() {
    this.getCartInfo()
  },
  methods: {
    ...mapActions('cart', [
      'getCartInfo'
    ])
  }
}
</script>