<template lang="pug">
  include ../../mixins
  +b.ns-counter
    +e.icon.A(
      href='', 
      @click.prevent='deleteMore()'
    ).mdi.mdi-minus
    +e.number.INPUT(
      v-model.number='count',
      @keyup='check'
      @change='check'
      type='number' 
      @input='changeCount'
    )
    +e.icon.A(
      href='', 
      :class='{"ns-counter__icon--disabled": cantIncrease}'
      @click.prevent='addMore()'
    ).mdi.mdi-plus
</template>
<script>
import debounce from 'lodash.debounce'
const INITIAL_COUNT = 1
export default {
  name: 'Counter',
  props: {
    amount: {
      type: [String, Number],
      default: () => INITIAL_COUNT
    },
    maxCount: {

    }
  },
  data() {
    return {
      one: 1,
      show: false,
      count: this.amount,
    }
  },
  watch: {
    amount(nval) {
      this.count = nval
    }
  },
  computed: {
    cantIncrease() {
      return this.maxCount <= this.count
    },
  },
  methods: {
    compare(e) {
    },
    check(e) {
      if (this.cantIncrease) {
        this.count = this.amount
        e.preventDefault()
        return
      }
      if ( e.target.value < this.one || '' == e.target.value ) {
        this.count = this.one
        e.preventDefault()
        return
      }
    },
    deleteMore() {
      this.count --
      this.count < this.one
        ? (this.count = this.one)
        : ""
      this.changeCount()
    },
    addMore() {
      this.count ++
      this.changeCount()
    },
    changeCount() {
      let time = 1000
      let action = debounce(this.emitter, time)
      action()
    },
    emitter() {
      this.$emit('change', { count: this.count } )
    }
  }

}
</script>

