import CatalogElements from '@app/Catalog'
import CartElements from '@app/Cart'
import FormsElements from '@app/Forms'
import OrderComponents from '@app/Order'
import PersonalElements from '@app/Personal'
import PurchaseElements from '@app/Purchase'
import RoutingElements from '@app/Routing'
import ReviewElements from '@app/Review'
import SeacrhElements from '@app/Search'
import UiElements from '@app/UiElements'

import resourceElements from '../resource'
export function install(Vue) {
  Vue.use(CartElements)
  Vue.use(CatalogElements)
  Vue.use(FormsElements)
  Vue.use(OrderComponents)
  Vue.use(PurchaseElements)
  Vue.use(PersonalElements)
  Vue.use(RoutingElements)
  Vue.use(ReviewElements)
  Vue.use(SeacrhElements)
  Vue.use(UiElements)
  Vue.use(resourceElements)
}

export default { install }
