<template lang="pug">
  include ../../mixins  
  +b.ds-panel--space_lg
    +e.element--offset_full
      +b.ds-caption--variant_primary.H5 {{ meta.message.header }}
      +b.ds-caption--variant_secondary.--size_xs.P {{ meta.message.text }}
</template>
<script>
export default {
  name: 'UiMessageModal',
  props: ['meta', 'promise', 'timeout'],
  data() {
    return {
      redirectTime: 3000
    }
  },
  mounted() {
    
    setTimeout(() => {
      if (this.promise != undefined) {
        this.promise('true')
      }
      this.$emit('close')
      if (this.meta.location) {
        if (this.meta.location.length) { 
          window.location.pathname = this.meta.location
        }
      }
    }, this.redirectTime)

  },
}
</script>

