import {
  compose, 
  defaultProps,
  setName
} from 'vue-compose'
import { withValidation } from 'vee-validate'

import UnifiedControlDescriptor from './UnifiedControlDescriptor'
import BoundLabel from './BoundLabel'
import StaticLabel from './StaticLabel'
import ErrorComponent from './ErrorComponent'

import MultiSelect from './Multiselect'

const SimpleInput = compose(
  setName('SimpleInput'),
  defaultProps({
    labelComponent: BoundLabel,
    errorComponent: ErrorComponent,
    component: 'Input'
  })
)(UnifiedControlDescriptor)

const SimpleInputV = withValidation(SimpleInput, ({ required, errors }) => ({
  isRequired: required,
  error: errors[0]
}))
const SimpleTextareaV = withValidation(
  SimpleInput, ({ required, errors }) => ({
    isRequired: required,
    error: errors[0],
    component: 'textarea'
  })
)

const StaticInput = compose(
  setName('StaticInput'),
  defaultProps({
    labelComponent: StaticLabel,
    errorComponent: ErrorComponent,
    component: 'Input'
  })
)(UnifiedControlDescriptor)


const Multiselect = compose(
  setName('Multiselect'),
  defaultProps({
    labelComponent: BoundLabel,
    errorComponent: ErrorComponent,
    component: 'MultiSelect'
  })
)(UnifiedControlDescriptor)

const MultiselectV = withValidation(Multiselect, ({ required, errors }) => ({
  isRequired: required,
  error: errors[0]
}))


export default function plugins(Vue, { prefix = 'Control' } = {} ) {
  Vue.component('MultiSelect', MultiSelect)
  Vue.component(`${prefix}SimpleInput`, SimpleInput)
  Vue.component(`${prefix}SimpleInputV`, SimpleInputV)
  Vue.component(`${prefix}SimpleTextareaV`, SimpleTextareaV)
  Vue.component(`${prefix}StaticInput`, StaticInput)
  Vue.component(`${prefix}Multiselect`, Multiselect)
  Vue.component(`${prefix}MultiselectV`, MultiselectV)
  Vue.component('BoundLabel', BoundLabel)
  Vue.component('ErrorComponent', ErrorComponent)
}
