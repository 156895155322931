import Multiselect from 'vue-multiselect'
import { createHOCc } from 'vue-hoc'

export const multiselectDefaults = createHOCc({
  props: [
    'placeholder', 
    'tagPlaceholder', 
    'showLabels', 
    'searchable', 
    'multiple',
    'closeOnSelect',
    'hideSelected',
    'taggable'
  ]
}, {
  props: {
    placeholder() {
      return this.placeholder || this._('Select an option')
    },
    tagPlaceholder() {
      return this.tagPlaceholder || ''
    },
    showLabels() {
      return this.showLabels || false
    },
    searchable() {
      return this.searchable || false 
    },
    multiple() {
      return this.multiple || false
    },
    closeOnSelect() {
      return this.closeOnSelect || true
    },
    hideSelected() {
      return this.hideSelected || false
    },
    taggable() {
      return this.taggable || true
    },
    placeholder() {
      return ''
    }
  }
})

export default multiselectDefaults(Multiselect)
