<template lang="pug">
  include ../../mixins
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    .relative
      +b.error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    +b.g-row
      +b.g-cell.g-cols--12.ns-space_mt--6
        control-simple-input-v(
          :rules="'required|email'",
          :input-label='labels.email'
          v-model='form.email'
          id='email',
          ref='email',
          autocomplete='off'
          name='email',
        )
      +b.g-cell.g-cols--12.ns-space_mt--6
        control-simple-input-v(
          :rules="'required'",
          :input-label='labels.password'
          v-model='form.password'
          autocomplete='new-password-5'
          id='password',
          type='password',
          name='password',
        )
    +b.g-row--justify_between
      +b.g-cell.g-cols--narrow.ns-space_mt--3
        +b.ns-link--appearance_underline.--accent.A.ds-caption--size_xs.A(
          :href='recovery_link'
        ) {{ _(' Забули пароль?') }}
      +b.g-cell.g-cols--narrow.ns-space_mt--3
        +b.ns-link--appearance_underline.--accent.A.ds-caption--size_xs.A(
          :href='register_link'
        ) {{ _(' Зареєструватися') }}

    +b.g-row
      +b.g-cell.g-cols--12.ns-space_mt--10
        +b.ns-button.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('Вход') }}
    +b.g-row--appearance_nowrap-till-xl.--justify_center
      +b.g-cell.g-cols.--12-xl.ns-space_mt--9
        +b.ds-caption--size-xs.--appearance_center-xl.P {{ _('Увійти за допомогою соціальних мереж:') }}
      +b.g-cell.g-cols.ns-space_mt--9.ns-space_mt-xl--7
        +b.facebook-link.A(
          href='/oauth/login/facebook/'
        )
          +e.img(src='/static/img/facebook.png')


</template>
<script>
import { loginResource } from '@api/auth.service'
import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'Login',
  mixins: [ FormMixin ],
  props: {
    noRedirect: {
      default: false
    }
  },
  data() {
    return {
      labels: {
        email: this._('Email'),
        password: this._('Password'),
      },
      form: {
        email: '',
        password: ''
      },
      isLoad: false,
      non_field_errors: '',
      recovery_link: window.recovery_page,
      register_link: window.registration_page,
    }
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return loginResource.execute({}, data)
        .then((res) => {
          if (!this.noRedirect) {
            AnswerMessage(res.meta, this)
          } else {
            window.location.reload()
          }
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  }
}
</script>
