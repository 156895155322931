<script>
import analyticCartAdd from '@analytic/add'

import { mapActions } from 'vuex'
import CartView from '@app/Cart/View'

import { 
  AnswerMessage,
} from '@utils/submit'
export default {
  name: 'AddToCart',
  props: {
    id: {
      type: [String, Number]
    },
    type: {
      type: String
    },
    amount: {
      type: [String, Number]
    },
    price: {},
    name: {},
    maxCount: {}
  },
  methods: {
    ...mapActions('cart', [
      'changeCart'
    ]),
    async addToCart(id, type, amount) {
      let data = {
        element: {
          id: this.id, 
          type: this.type
        },
        quantity: this.amount
      }
      let entities = []
      entities.push(data)
      try {
        await this.changeCart({ entities: entities })
        analyticCartAdd(
          this.name, this.id, this.price, this.amount
        )
        this.openCart()
        this.$emit('success')
      } catch (e) {
        let meta = {
          message: {
            header: `${this._('Максимальна кількость товарів перевищена. Даний товар вже є у кошику')} `,
            text: `${this._('Цей Товар можна замовити лише у кількості')} ${this.maxCount} ${this._('шт.')}`,
          }
        }
        AnswerMessage(meta, this)
      }
     
    },
    openCart() {
      this.$modal.show(
        () => import('@app/Cart/View'),
        {}, {
          height: 'auto',
          classes: ["extralarge-modal"],
          adaptive: true,
          scrollable: true
        })
    }
  },
  render(h) {
    return this.$scopedSlots.default({
      add: () => {
        this.addToCart()
      }
    })
  }
}
</script>