<template lang="pug">
  include ../../mixins
  +b.g-row.ns-space_mt--10
    +b.g-cell.g-cols--12
      +b.ds-caption--size_2md.--size_lg-xl.--medium.H2 {{ _('3. Додаткова інформація') }}
    +b.g-cell.g-cols--12.ns-space_mt--6 
      control-simple-textarea-v(
        :input-label='labels.comment'
        rows='4'
        @input='send("customer_note")'
        v-model='form.customer_note',
        name='customer_note'
      )
    
</template>
<script>
export default {
  name: 'OrderPersonal',
  data() {
    return {
      form: {
        customer_note: '',
      },
      labels: {
        comment: this._('Коментар до замовлення')
      }
    }
  },
  methods: {
    send(key) {
      let obj = {
        key: key,
        value: this.form[key]
      }
      this.$emit('setData', obj)
    }
  }
}
</script>

 