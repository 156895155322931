// import compileProducts from '@analytic/utils'
export default function analyticCartRemove(products) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []

  // let objects_list = compileProducts(products)

  dataLayer.push({
    'ecommerce': {
      'currencyCode': 'UAH',
      'remove': {
        'products': products
      }
    },
    'event': 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Removing a Product from a Shopping Cart',
    'gtm-ee-event-non-interaction': 'False',
   
  })
}



