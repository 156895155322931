<template>
  <div>
    <filter-controller
      :receive="allProps.receive"
      :receiveAvailable="availableProps.receive"
      :all="allProps.result && allProps.result.data.items"
      :available="availableProps.result && availableProps.result.ids"
      :displayed="displayedFilters"
      :loading="allProps.loading || availableProps.loading"
      :value="value"
      @input="handleFilter"
    />
  </div>
</template>

<script>
import { analyticImpressionHandler } from '@analytic/impressions'

import { attributesResource, availableAttributesResource } from '@api/catalog.service'
import FilterController from './Controller'
import {
  FILTERS_SHORTEN,
  FILTERS_EXTENDED,
  FILTERS_ALL,
  omitExtended,
} from './const'

export default {
  props: ['value', 'allProps', 'availableProps'],
  
  attributesResource,
  availableAttributesResource: availableAttributesResource,
  components: {
    FilterController,
  },

  data() {
    const allFilters = FILTERS_EXTENDED
      .some(key => (this.value.filters 
        && key in this.value.filters && this.value.filters[key]))
    return {
      allFilters,
      displayedFilters: this.getDisplayedFilters(allFilters),
    }
  },

  methods: {
    handleFilter(parameters) {
      analyticImpressionHandler('.product')
      this.$emit('input', this.clearExtendedFilters(parameters))
    },

    toggleAllFilters() {
      this.setFiltersAvailability(!this.allFilters)
    },

    getDisplayedFilters(all) {
      return all ? FILTERS_ALL : FILTERS_SHORTEN
    },

    clearExtendedFilters(parameters) {
      if (this.allFilters) {
        return parameters
      }

      const { filters = {}, ...rest } = parameters
      return { filters: omitExtended(filters), ...rest }
    },

    setFiltersAvailability(all) {
      this.allFilters = all
      this.displayedFilters = this.getDisplayedFilters(this.allFilters)
    },
  },
}

</script>
