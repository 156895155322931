import { renderSlim } from '@aspectus/vue-utils'
import LoadingState from '@app/UiElements/mixins/LoadingState'

const createChecker = (callback, promise) => value => callback(value, promise)

export default {
  name: 'resource-loader-controller',
  mixins: [LoadingState],

  props: {
    resource: {},
  },

  data() {
    return {
      result: null,
    }
  },

  methods: {
    applyResult(result, promise) {
      if (this.$options.promise !== promise) {
        return
      }
      this.result = result
    },

    receive(parameters) {
      if (this.$options.promise && this.$options.promise.cancel) {
        this.$options.promise.cancel()
      }
      const promise = this.resource.execute(parameters)
      this.$options.promise = promise

      return this.$load(promise.then(createChecker(this.applyResult, promise)))
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      loading: this.loading,
      result: this.result,
      receive: this.receive,
    }), h, 'tag')
  },
}
