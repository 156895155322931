export default function compileProducts(
    arr
) {
  if (!window.is_analytic_enabled) return
  let result = arr.reduce((acc, obj, idx) => {
    acc = acc || []
    let product = {
      'name': obj.base.element.caption,
      'id': obj.base.element.id,
      'price': obj.base.price,
      'brand': '',
      'category': '',
      'quantity': obj.base.quantity
    }
    acc.push(product)
    return acc
  }, [])
  return result
}

