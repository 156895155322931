<template lang="pug">
  include ../../mixins
  +b.control-stack.ns-space_mt--6.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.g-row--align_center.--justify_between.--justify_end-xl.ns-space_mt--6
      cell(
        cols='12'
      )
        control-simple-textarea-v(
          :input-label='labels.comment'
          :rules="'required|min:5'",
          rows='4'
          v-model='form.comment',
          name='comment',
          ref='comment'
          error-classes='error-label--top'
        )
      cell(
        cols='6'
      )  
      cell(
        cols='6 3-xl'
      )  
        +b.ns-button.BUTTON.ns-space_mt--6(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('Надiслати') }}
</template>

<script>

import {
  ReviewCreate
} from '@api/vendor.service'

import { 
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'ReviewAdd',
  mixins: [ FormMixin ],
  props: {
    user_name: {
      type: String
    },
    user_email: {
      type: String
    },
    product_type: {
      type: String
    },
    product_id: {
      type: String
    }

  },
  data() {
    return {
      labels: {
        comment: this._('Ваше враження')
      },
      form: {
        comment: '',
        name: this.user_name,
        email: this.user_email,
        element: {
          type: this.product_type,
          id: this.product_id
        }
      },
      non_field_errors: '',
      isLoad: false
    }
  },

  methods: {
    
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return ReviewCreate.execute({}, data)
        .then((res) => {
          AnswerMessage(res.meta, this)
          this.form.comment = null
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  }
  
}
</script>