<template lang='pug'>
  div
    renderer(
      :result='items',
      v-if='items'
    )
    slot(v-else)
    ui-limit-pagination(
      :pagination='pagination'
      @input='handlePaginate'
    )
</template>

<script>
import Renderer from '@app/Catalog/Renderer'
export default {
  props: ['loading', 'items', 'pagination'],
  components: {
    Renderer
  },
  computed: {
    back() {
      if (this.items) {} // eslint-disable-line no-empty
      return `${window.location.pathname}${window.location.search}`
    },
  },

  methods: {
    handlePaginate({ offset }) {
      if (this.loading) {
        return
      }
      let init = 0
      const { limit = init } = this.pagination
      this.$emit('update:pagination', { limit, offset: offset })
    },
  },
}

</script>
