<script>
export default {
  name: 'UiAccordion',
  props: {
    active: {
      default: () => false
    },
    collapsable: {
      default: () => false
    },
    id: { },
    fullHeight: {
      default: () => false
    }, 
    init: {
      default: () => true
    },

  },
  data() {
    return {
      activeState: this.active,
      windWidth: 1200,
      one: 1,
      zero: 0,
      contentHeight: 0,
      isDesktop: false
    }
  },
  computed: {
    style() {
      return {
        icon: this.activeState ? 'mdi-chevron-up' : 'mdi-chevron-down',
        active: this.activeState ? this.one : this.zero,
        myHeight: this.activeState ? 
          this.stylesGenerator(this.contentHeight) : this.zero
      }
    },
  },
  mounted() {
    this.checkUrl()
    this.resizeHandler()
    this.calcHeight()
    this.initialCheck()
    this.checkWidth()
  },
  methods: {
    stylesGenerator(val) {
      if (this.fullHeight && !this.isDesktop) {
        return 'initial'
      }
      return `${val}px`
    },
    initialCheck() {
      this.activeState = this.init
    },
    calcHeight() {
      let el = this.$el.getElementsByClassName('ns-accordion__description')[0]
      if (el) {
        this.contentHeight = el.scrollHeight
      } 
    },
    checkWidth() {
      if (window.innerWidth > this.windWidth) {
        this.isDesktop = true 
      }
    },
    resizeHandler() {
      if (window.innerWidth < this.windWidth) {
        this.activeState = false
      } else {
        this.activeState = true
        this.isDesktop = true
      }
    },
    toggleActive(optional) {
      if ('resizable' === optional ) {
        if (window.innerWidth > this.windWidth) {
          return
        }
      } 
      this.activeState = !this.activeState 
      this.calcHeight()
      this.checkWidth()
    },
    checkUrl() {
      let hash = location.hash
      if (hash) {
        hash = hash.split('#')
        hash = hash[this.one]
        if (hash == this.id) {
          this.activeState = true
        }
      }
    },
    async scrolly(time) {
      setTimeout(async () => {
        let offset = 90
        var top = document.getElementById(this.id).documentOffsetTop() - offset
        window.scrollTo({ top: top, behavior: 'smooth' })
      }, time)
      
    },
  },
  
  render(h) {
    return this.$scopedSlots.default({
      style: this.style,
      activeState: this.activeState,
      toggleActive: this.toggleActive,
      isDesktop: this.isDesktop,
      collapse: () => {
        if ( this.isDesktop ) {
          return
        } 
        this.activeState = false
      }
    })
  }
}
</script>