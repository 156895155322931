<template lang="pug">
  include ../../../mixins
  +b.ds-panel--space_xl
    +e.element--offset_horizontal
      
      template(v-for='val in filter.attribute.values')
        +b.ns-space_mt--3
          +b.d-control-input--appearance_clear.--appearance_flexible
            +e.element.INPUT(
              type='checkbox'
              :disabled='notAvailable(val.id)'
              :value='val.label',
              :name='filter.attribute.label'
              v-model='checked'
              :input-label='val.title',
              :id='`${filter.attribute.label}:${val.id}`'
              @change='submit()'
            )
            +e.LABEL.label--static(
              :for='`${filter.attribute.label}:${val.id}`'
            ) {{ val.title }} 
</template>
<script>
export default {
  name: 'CheckboxRenderer',
  props: ['value', 'filter', 'available'],
  data() {
    return {
      checked: []
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    }
  },
  mounted() {
    this.checked = this.normalize(this.value)
    
  },
  methods: {
    submit() {
      this.$emit('input', this.checked)
    },
    notAvailable(id) {
      let length = Object.keys(this.available).length
      let answer = false
      if ( length ) {
        if ( this.available[id]) { 
          answer = false
        } else {
          answer = true
        }
      } else {
        answer = false
      }
      return answer
    },
    normalize(value) {
      if (!value) return []
      let checked = this.filter.attribute.values.filter(el => {
        return value.find(v => v == el.label)
      }).map(el => el.label)
      return checked
    }
  }
}
</script>