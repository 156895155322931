const messages = {
  [window.language]: window.django.catalog || {}
}

const dateTimeFormats = {}

function translator() {
  return this.$t.apply(this, arguments)
}

export function createI18n({ Vue, I18n }) {
  Vue.prototype._ = translator

  const i18n = new I18n({
    locale: window.language,
    silentTranslationWarn: true,
    dateTimeFormats,
    messages,
  })
  return i18n
}
