const language = window.language

export function checkLogo() {
  let location = ['/', `/${language}/`, '/index.html']
  let onesec = 1
  location.forEach(el => {
    let logo = Array.from(document.getElementsByClassName('logo-wrapper'))
    if (el == window.location.pathname) {
      setTimeout(() => {
        logo.forEach(element => {
          element.style.pointerEvents = 'none'
        })
      }, onesec)
    } else {
      logo.forEach(element => {
        element.style.pointerEvents = 'all'
      })
    }
  })  
}
