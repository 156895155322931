import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const SEACRH_URL = prefixAPI('search/?search={search}')
const REVIEW_CREATE_URL = prefixAPI('/create/', 'reviews')
const REVIEW_LIST_URL = prefixAPI('/list/{?id,type,offset}', 'reviews')

export const searchResource = createResource(SEACRH_URL)
export const ReviewCreate = createResource(REVIEW_CREATE_URL, postResource)
export const ReviewListResoure = createResource(REVIEW_LIST_URL)

