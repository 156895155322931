import {
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const ATTRIBUTES_URL = prefixAPI('attributes/filter-list/')
export const attributesResource = createResource(ATTRIBUTES_URL)

const AVAILABLE_ATTRIBUTES_URL = `/ajax/available-attributes/{;filters*}`
export const availableAttributesResource = 
  createResource(AVAILABLE_ATTRIBUTES_URL)

const FILTERS_LIST_URL = `/ajax/filters/{;filters*}{?limit,offset}`
export const productsListUrl = createResource(FILTERS_LIST_URL)



