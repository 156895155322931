import PaginatedResourceController from './PaginatedResourceController'
import ResourceLoaderController from './ResourceLoaderController'
import InfiniteResultsCollector from './InfiniteResultsCollector.js'
import PaginationController from './PaginationController'

function install(Vue) {
  Vue.component(PaginatedResourceController.name, PaginatedResourceController)
  Vue.component(ResourceLoaderController.name, ResourceLoaderController)
  Vue.component(InfiniteResultsCollector.name, InfiniteResultsCollector)
  Vue.component(PaginationController.name, PaginationController)
}

export default {
  install,
}
