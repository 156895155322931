export default function analyticClick(
    name, id, price, brand = '', category = ''
) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []
  let object_list = window.object_list || ""
  dataLayer.push({
    'ecommerce': {
      'currencyCode': 'UAH',
      'click': {
        'actionField': {
          'list': object_list
        },
        'products': [{
          'name': name,
          'id': id,
          'price': price,
          'brand': brand,
          'category': category,
        }]

      }
    },
    'event': 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Product Clicks',
    'gtm-ee-event-non-interaction': 'False',
  })
}
