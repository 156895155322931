<template lang='pug'>
  router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initial"
    :to="parametersTo"
    :from="parametersFrom"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="$options.productsResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      v-slot="paginatedProps"
    )
      controller(
        ref="controller"
        :loading='false'
        v-bind="paginatedProps"
      )
        slot
</template>

<script>
import { mapMutations } from 'vuex'

import { productsListUrl } from '@api/catalog.service'

import { 
  friendlyUrlParser,
} from '@app/Filters/transformers'

import Controller from '@app/Catalog/Controller'

export default {
  productsResource: productsListUrl,
  components: { Controller },
  props: {
    initial: {

    }
  },
  name: 'CatalogView',
  data() {
    return {

    }
  },

  methods: {
    ...mapMutations('search', [
      'SET_SEARCH'
    ]),
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {}
      }
    },
    parametersFrom({...rest}) {
      let data = friendlyUrlParser()
      if (data.search) {
        this.SET_SEARCH(data.search[0])
      }
      // try {
      //   data = JSON.parse(b64DecodeUnicode(filters))
      // } catch (e) {}

      return {
        ...rest,
        filters: data,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
