import { partialRight } from 'ramda'
import is from 'is_js_new'

const one = 1
const zero = 0


export function snakelize(key) {
  const separator = '_'
  const split = /(?=[A-Z])/
  
  return key.split(split)
    .join(separator)
    .toLowerCase()
    .replace(/-+/img, '_')
    .replace(/_+/img, '_')
}

export function kebabalize(key, snaked = false) {
  return (snaked ? key : snakelize(key)).replace(/_+/img, '-')
}

export function camelize(key) {
  if (is.number(key)) {
    return key
  }
  key = key.replace(/[-_\s]+(.)?/g, (match, ch) => (ch ? ch.toUpperCase() : ''))
  // Ensure 1st char is always lowercase
  return key.substr(zero, one).toLowerCase() + key.substr(one)
}

export function titleize(key) {
  return key.charAt(zero).toUpperCase() + key.substr(one)
}

/*
 * Camelize/snakelize keys of an object.
 *
 * @param {number} depth - to which level of keys should it process.
 */
function processKeys(obj, processor, depth) {
  if (depth === zero || !is.object(obj)) {
    return obj
  }

  const result = {}
  const keys = Object.keys(obj)

  for (let i = zero; i < keys.length; i += one) {
    result[processor(keys[i])] =
      processKeys(obj[keys[i]], processor, depth - one)
  }

  return result
}

function convertorFactory(processor) {
  return function convertor(data, depth = one) {
    if (!is.object(data)) {
      return processor(data)
    }

    return processKeys(data, processor, depth)
  }
}

/**
 * @param {Object|String} data - string or keys of object are named
 * in form of snake.
 * @param {number} depth - to which level of keys should it process.
 * @return {Object|String} - string or keys of object are named in form
 * of camel case.
 */
export const snakeToCamel = convertorFactory(camelize)

/**
 * @param {Object|String} data - string or keys of object are named in
 * form of camel case.
 * @param {number} depth - to which level of keys should it process.
 * @return {Object|String} - string or keys of object are named in form
 * of snake.
 */
export const camelToSnake = convertorFactory(snakelize)

/**
 * @param {Object|String} data - string or keys of object are named in
 * form of snake case.
 * @param {number} depth - to which level of keys should it process.
 * @return {Object|String} - string or keys of object are named in form
 * of (kebab|lisp|spinal)-case.
 */
export const snakeToKebab = convertorFactory(partialRight(kebabalize, [true]))

/**
 * @param {Object|String} data - string or keys of object are named in
 * form of camel case.
 * @param {number} depth - to which level of keys should it process.
 * @return {Object|String} - string or keys of object are named in form
 * of (kebab|lisp|spinal)-case.
 */
export const camelToKebab = convertorFactory(kebabalize)

/**
 * @param {Object|String} data - string or keys of object are named in
 * form of (kebab|lisp|spinal)-case.
 * @param {number} depth - to which level of keys should it process.
 * @return {Object|String} - string or keys of object are named in form
 * of snake case.
 */
export const kebabToSnake = camelToSnake

/**
 * @param {Object|String} data - string or keys of object are named in
 * form of (kebab|lisp|spinal)-case.
 * @param {number} depth - to which level of keys should it process.
 * @return {Object|String} - string or keys of object are named in form
 * of camel case.
 */
export const kebabToCamel = snakeToCamel

