import analyticClick from '@analytic/clicks'
import { analyticImpressionHandler } from '@analytic/impressions'
import { checkLogo } from './ui-scripts'
import './filters'



document.addEventListener('DOMContentLoaded', e => {
  checkLogo()

  let products = Array.from(document.getElementsByClassName('product'))
  products.forEach(el => {
    el.addEventListener('click', (e) => {
      let parent = e.target.closest('.product')
      analyticClick(
        parent.dataset.name,
        parent.dataset.id,
        parent.dataset.price,
      )
    })
  })

  analyticImpressionHandler('.product')

  

})


