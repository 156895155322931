
import { createStore as createCartStore } from './cart'
import { createStore as createSearchStore } from './search'

export function createStore(parameters) {
  return {
    modules: {
      cart: createCartStore(parameters),
      search: createSearchStore(parameters),
    }
  }
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }))
}
