<template lang="pug">
  include ../../../mixins
  +b-context('input-group')
    +e.LABEL.label(:for='id') {{ inputLabel }} 
      +e.is_required.SPAN(v-if='isRequired') *
</template>

<script>
export default {
  name: 'BoundLabel',
  props: {
    id: {
      type: String,
      required: false
    },
    value: {},
    inputLabel: {
      type: String
    },
    isRequired: {
      type: Boolean
    }
  }
}
</script>