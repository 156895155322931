import Components from './components'

function install(Vue) {
  Vue.use(Components)
}

export default {
  install,
}

