<template lang="pug">
  include ../../mixins
  +b.ds-panel--space_lg
    ui-loader(v-if='load')
    +e.element--indent_inside.--offset_vertical.product_in_cart.relative
      +b.g-row--align_center
        +b.g-cell.g-cols--3.--2-xl.relative
          +b.ns-picture--size_auto.--size_auto-h.product__img.product__img--mini.PICTURE
            source(
              type="image/webp" 
              :srcset="product.base.element.props.image.catalog_preview_webp"
            )
            +e.body.IMG(
              v-if='product.base.element.props.image.catalog_preview'
              :src='product.base.element.props.image.catalog_preview',
              :alt='product.base.element.props.title',
            )
            +e.body.IMG(
              v-else
              src='http://via.placeholder.com/60x60',
            )
        +b.g-cell.g-cols--8.--5-xl
          +b.g-row--appearance_column.--justify_between.full-h 
            +b.g-cell.g-cols--12
              +b.ds-caption--size_xs.--size_sm-xl.--medium.P {{ product.base.element.props.title }}
            +b.g-cell.g-cols--12.is-visible-xl.ns-space_mt--3
              +b.g-row 
                +b.g-cell.g-cols--6
                  +b.ds-caption--size_xs.--regular.P(
                    v-if='product.base.element.props.weight'
                  ) {{ product.base.element.props.weight.value }} {{ product.base.element.props.weight.title }}  
                +b.g-cell.g-cols--6
                  +b.ds-caption--size_xs.--bold.appearance_right.P {{ product.base.price }} {{ _('грн') }}
            +b.g-cell.g-cols--12.ns-space_mt--3.is-visible-xl(
              v-if='Number(limit) >= product.base.element.props.quantity'
            )
              +b.ds-caption--size_xs.--regular.--color_red.P(v-if='product.base.element.props.quantity == product.base.quantity') {{ _('Товар можна замовити лише у кількості')}} {{ product.base.element.props.quantity }} {{ _("шт") }}
              +b.ds-caption--size_xs.--regular.--color_accent.P(v-else) {{ _('Максимальна кількість одиниць для замовлення') }} &nbsp; {{ product.base.element.props.quantity }} {{ _('шт.') }}
            +b.g-cell.g-cols--12.ns-space_mt--3.is-visible-xl(
              v-else-if='product.base.element.props.quantity == product.base.quantity'
            )
              +b.ds-caption--size_xs.--regular.--color_red.P {{ _('Товар можна замовити лише у кількості')}} {{ product.base.element.props.quantity }} {{ _("шт") }}
        +b.g-cell.g-cols--6.--6-xl.is-disable-xl.ns-space_mt--2
          +b.ds-caption--size_xs.--regular.P(
            v-if='product.base.element.props.weight'
          ) {{ product.base.element.props.weight.value }} {{ product.base.element.props.weight.title }}  

        +b.g-cell.g-cols--6.--6-xl.is-disable-xl.ns-space_mt--2
          +b.ds-caption--size_xs.--bold.--appearance_right.P {{ product.base.price }} {{ _('грн') }}

        +b.g-cell.g-cols--6.--2-xl
          counter(
            :amount='product.base.quantity',
            :max-count='product.base.element.props.quantity'
            @change='$emit("updateCart", $event)'
          )
        +b.g-cell.g-cols--6.--3-xl
          +b.ds-caption--size_md.--bold.--appearance_right-till-xl.P {{ product.price }} {{ _('грн') }}
        +b.g-cell.g-cols--12.ns-space_mt--3.is-disable-xl(
          v-if='Number(limit) >= product.base.element.props.quantity'
        )
          +b.ds-caption--size_xs.--regular.--color_red.P(v-if='product.base.element.props.quantity == product.base.quantity') {{ _('Товар можна замовити лише у кількості')}} {{ product.base.element.props.quantity }} {{ _("шт") }}
          +b.ds-caption--size_xs.--regular.--color_accent.P(v-else) {{ _('Максимальна кількість одиниць для замовлення') }} &nbsp; {{ product.base.element.props.quantity }} {{ _('шт.') }}
        +b.g-cell.g-cols--12.ns-space_mt--3.is-disable-xl(
          v-else-if='product.base.element.props.quantity == product.base.quantity'
        )
          +b.ds-caption--size_xs.--regular.--color_red.P {{ _('Товар можна замовити лише у кількості')}} {{ product.base.element.props.quantity }} {{ _("шт") }}
        +b.remove-from-cart.SPAN.mdi.mdi-close(
          @click='$emit("removeFromCart")'
        )
</template>
<script>
export default {
  name: 'ProductUi',
  props: {
    product: {
      default: () => { }
    },
    load: {}
  },
  data() {
    return {
      limit: window.availability_limit,
    }
  }
}
</script>