import { Tabs, Tab } from 'vue-slim-tabs'
import VueTag from '@aspectus/vue-tag'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
import HeaderComponents from '@app/UiElements/Header'
import ControlsComponents from '@app/UiElements/Controls'
import DrawerReceiver from './Drawer/Receiver'
import UiClickOutside from './UiClickOutside'
import ModalTrigger from './ModalTrigger'
import SeoText from './SeoText'
import UiAccordion from './UiAccordion'
import UiLoader from './UiLoader'
import LoadingWrapper from './LoadingWrapper'
import Lightbox from './Lightbox'
import UiLimitPagination from './Pagination/UiLimitPagination'
export default function install(Vue) {
  Vue.component('Tabs', Tabs)
  Vue.component('Tab', Tab)

  Vue.use(HeaderComponents)
  Vue.use(ControlsComponents)
  Vue.use(VueTag)
  Vue.use(VueBem)
  Vue.use(VueGrid)

  Vue.component(UiClickOutside.name, UiClickOutside)
  Vue.component(ModalTrigger.name, ModalTrigger)
  Vue.component(SeoText.name, SeoText)
  Vue.component(UiAccordion.name, UiAccordion)
  Vue.component(UiLoader.name, UiLoader)
  Vue.component(LoadingWrapper.name, LoadingWrapper)
  Vue.component(Lightbox.name, Lightbox)
  
  Vue.component(DrawerReceiver.name, DrawerReceiver)
  Vue.component(UiLimitPagination.name, UiLimitPagination)
}
