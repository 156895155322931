<template lang="pug">
  include ../../mixins
  +b.g-row
    +b.g-cell.g-cols--12
      +b.ds-caption--size_2md.--size_lg-xl.--medium.H2 {{ _('1. Оформлення замовлення') }}
    +b.g-cell.g-cols--12.ns-space_mt--6 
      control-simple-input-v(
        :rules="'required'",
        :input-label='labels.customer_name'
        v-model='form.customer_name'
        id='customer_name',
        ref='customer_name'
        @input='send("customer_name")'
        name='customer_name',
      )
    +b.g-cell.g-cols--12.ns-space_mt--6 
      control-simple-input-v(
        :rules="'required|email'",
        :input-label='labels.customer_email'
        v-model='form.customer_email'
        @input='send("customer_email")'
        id='customer_email',
        ref='customer_email',
        name='customer_email',
      )  
    +b.g-cell.g-cols--12.ns-space_mt--6 
      control-simple-input-v(
        :rules="'required|phone'",
        @input='send("customer_phone")'
        :input-label='labels.customer_phone'
        v-model='form.customer_phone'
        id='customer_phone',
        ref='customer_phone',
        name='customer_phone',
      )  
</template>
<script>
import { masking } from '@utils/helpers'

export default {
  name: 'OrderPersonal',
  props: {
    user: {
      
    }
  },
  data() {
    return {
      labels: {
        customer_name: this._('Ваше ім‘я'),
        customer_email: this._('Email'),
        customer_phone: this._('Телефон')
      },
      form: {
        customer_name: '',
        customer_email: '',
        customer_phone: ''
      },
      zero: 0,
      not_sended: true
    }
  },
  watch: {
    user(nval) {
      this.form.customer_name = `${nval.first_name} ${nval.last_name}` 
      this.form.customer_email = nval.email
      this.form.customer_phone = nval.phone
      const keys = ['customer_name', 'customer_email', 'customer_phone']
      keys.forEach(k => {
        if (this.form[k]) {
          this.$refs[k].flags.valid = true
          this.$refs[k].errros = []
        }
        this.send(k)
      })

    }
  },
  mounted() {
    masking('customer_phone', this)
  },
  methods: {
    send(key) {
      let obj = {
        key: key,
        value: this.form[key]
      }
      this.$emit('setData', obj)
      
      
    }
  },
}
</script>