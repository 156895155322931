<template lang="pug">
  include ../../mixins
  +b.g-row.ns-space_mt--10
    +b.g-cell.g-cols--12
      +b.ds-caption--size_2md.--size_lg-xl.--medium.H2 {{ _('2. Вибір аптеки для самовивозу') }}
    +b.g-cell.g-cols--12.ns-space_mt--6 
      +b.g-row--align_center
        +b.g-cell.g-cols--narrow  
          +b.ds-caption.--size_xl.--color_accent.mdi.mdi-information-outline.SPAN
        +b.g-cell.g-cols--narrow  
          +b.ds-caption.--size_sm.P {{ _('Увага! Термін доставки по Україні 2-3 дні') }}
        
    +b.g-cell.g-cols--12.ns-space_mt--6 
      control-multiselect-v(
        :options='cities'
        :rules="'required'",
        v-model='form.city'
        label="caption",
        :searchable='true'
        @search-change="getCities"
        @input="getPharma('pharma'), send('city')"
        :input-label='labels.city'
        track-by='id'
        id='city'
        ref='city'
        name='city'
      )
    +b.g-cell.g-cols--12.ns-space_mt--6 
      control-multiselect-v(
        :options='pharmas'
        :rules="'required'",
        v-model='form.pharmacy'
        label="caption",
        ref='pharmacy'
        :searchable='true'
        @input='send("pharmacy")'
        @search-change="getPharma"
        :input-label='labels.pharmacy'
        :custom-label='addressAndSplittedTitle'
        track-by='id'
        id='pharmacy'
        name='pharmacy'
      )
    +b.g-cell.g-cols--12(v-if='form.city')
      row(justify='end')
        cell(cols='narrow')
          +b.MODAL-TRIGGER(
            :classes='["extralarge-modal", "mapPopup"]',
            name='Pharmacy',
            :pharmas='pharmas',
            :selected='form.pharmacy'
            :pr='promise'
            url='Order/Pharmacy'
          )
            template(#default='{ open }')
              +b.ns-link--appearance_underline.--accent.A.ds-caption--size_xs.A(
                href='',
                @click.prevent='open'
              )
                +e.title.P {{ _('Показати на карті') }}

</template>
<script>
import { 
  CitiesResource,
  PharmaResource
} from '@api/order.service'
export default {
  name: 'OrderDelivery',
  props: {
    user: {
      
    }
  },
  data() {
    return {
      labels: {
        city: this._('Містo'),
        pharmacy: this._('Відділення'),
      },
      form: {
        city: '',
        pharmacy: ''
      },
      cities: [],
      pharmas: [],
      promise: ''
    }
  },
  watch: {
    async user(nval) {
      const keys = ['city', 'pharmacy'] 
      keys.forEach(k => {
        this.form[k] = nval[k]
        if (this.form[k]) {
          this.$refs[k].flags.valid = true
          this.$refs[k].errros = []
          this.send(k)
        }
      })
      this.getPharma().then(() => {
        this.form.pharmacy = nval.pharmacy
        if (this.form.pharmacy) {
          this.$refs.pharmacy.flags.valid = true
          this.$refs.pharmacy.errros = []
          this.send('pharmacy')
        }
      }) 
    }
  },
  mounted() {
    this.getCities()
    this.getPharma()
    this.setPromise()
  },
  methods: { 
    setPromise() {
      this.promise = (arg) => {
        return new Promise((resolve, reject) => {
          resolve(arg)
          reject(new Error('promise rejected'))
        }).then((res) => {
          this.form.pharmacy = res
          this.$refs.pharmacy.flags.valid = true
          this.$refs.pharmacy.errros = []
          this.send('pharmacy')
        })
      }
    },
    async getCities(query) {
      let params = {
        search: 'city' == query ? '' : query,
        limit: 300,
      }
      let { data } = (await CitiesResource.execute(params))
      this.cities = data.items
    },
    async getPharma(query) {
      this.form.pharmacy = ''
      let params = {
        search: 'pharma' == query ? '' : query,
        city: this.form.city ? this.form.city.id : '',
        limit: 300,
      }
      let { data } = (await PharmaResource.execute(params))
      this.pharmas = data.items
    },
    addressAndSplittedTitle({ props: { address, title } }) {
      return `${title.split('(')[0]} ${address}`
    },
    send(key) {
      let obj = {
        key: key,
        value: this.form[key]
      }
      this.$emit('setData', obj)
    }
  }
}
</script>