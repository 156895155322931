<template lang="pug">
  include ../../mixins
  //- vue-image-loader
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.g-row
      +b.g-cell.g-cols--12.--6-xl.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required'",
          :input-label='labels.name'
          v-model='form.name'
          id='name',
          ref='name',
          name='name',
        )
      +b.g-cell.g-cols--12.--6-xl.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.phone'
          id='phone',
          ref='phone',
          name='phone',
        )  
      +b.g-cell.g-cols--12.--6-xl.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required|email'",
          :input-label='labels.email'
          v-model='form.email'
          id='email',
          ref='email'
          name='email',
        )
      +b.g-cell.g-cols--12.--6-xl.ns-space_mt--6 
        control-simple-input-v(
          :input-label='labels.password'
          v-model='form.password'
          id='password',
          autocomplete='new-password'
          type='password',
          ref='password'
          name='password',
        )
      +b.g-cell.g-cols--12.--6-xl.ns-space_mt--6 
        control-multiselect-v(
          :options='cities'
          :rules="'required'",
          v-model='form.city'
          label="caption",
          :searchable='true'
          @search-change="getCities"
          @input="clearPharma('pharma')"
          :input-label='labels.city'
          track-by='id'
          id='city'
          ref='city'
          name='city'
        )
      +b.g-cell.g-cols--12.--6-xl.ns-space_mt--6 
        control-multiselect-v(
          :options='pharmas'
          :rules="'required'",
          v-model='form.pharmacy'
          label="caption",
          ref='pharmacy'
          :searchable='true'
          @search-change="getPharma"
          :input-label='labels.pharmacy'
          track-by='id'
          id='pharmacy'
          name='pharmacy'
        )
      +b.g-cell.g-cols--12.--6-xl
      +b.g-cell.g-cols--12.--6-xl(v-if='form.city')
        row(justify='end')
          cell(cols='narrow')
            +b.MODAL-TRIGGER(
              :classes='["extralarge-modal", "mapPopup"]',
              name='Pharmacy',
              :pharmas='pharmas',
              :selected='form.pharmacy'
              :pr='promise'
              url='Order/Pharmacy'
            )
              template(#default='{ open }')
                +b.ns-link--appearance_underline.--accent.A.ds-caption--size_xs.A(
                  href='',
                  @click.prevent='open'
                )
                  +e.title.P {{ _('Показати на карті') }}
    +b.ns-space_mt--10.ROW(justify='center')
      +b.g-cell.g-cols--12.--6-xl
        +b.ns-button.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('збрегти зміни') }}          
        

</template>

<script>
// import VueImageLoader from 'src/packages/vue-image-loader/src'


import pathOr from 'ramda/src/pathOr'

import { 
  userInfo, 
  userInfoUpdate,
} from '@api/auth.service'
import { 
  CitiesResource,
  PharmaResource
} from '@api/order.service'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { masking } from '@utils/helpers'
import { 
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'PersonalView',
  mixins: [ FormMixin ],
  components: {
    // VueImageLoader
  },
  data() {
    return {
      form: {
        name: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        city: '',
        pharmacy: '',
      },
      labels: {
        name: this._('Ваше ім‘я'),
        email: this._('Email'),
        password: this._('Set your new Password'),
        phone: this._('Телефон'),
        city: this._('Містo'),
        pharmacy: this._('Відділення'),
      },
      user: {},
      cities: [],
      pharmas: [],
      promise: '',
      isLoad: false,
    }
  },
  mounted() {
    masking('phone', this)
    this.getCities()
    this.getPharma()
    this.setPromise()
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      try {
        let { data: { props } } = (await userInfo.execute())
        this.form.name = `${props.first_name} ${props.last_name}`
        this.$refs.name.flags.valid = true
        this.$refs.name.errros = []
        const keys = ['email', 'phone', 'pharmacy', 'password', 'city']
        keys.forEach(k => {
          this.form[k] = props[k]
          if (this.form[k]) {
            this.$refs[k].flags.valid = true
            this.$refs[k].errros = []
          }
        })
        if (this.form.city) {
          this.getPharma()
        }
      } catch (e) {
      }
    },
    setPromise() {
      this.promise = (arg) => {
        return new Promise((resolve, reject) => {
          resolve(arg)
          reject(new Error('promise rejected'))
        }).then((res) => {
          this.form.pharmacy = res
          this.$refs.pharmacy.flags.valid = true
          this.$refs.pharmacy.errros = []
        })
      }
    },
    async getCities(query) {
      let params = {
        search: 'city' == query ? '' : query,
        limit: 300
      }
      let { data } = (await CitiesResource.execute(params))
      this.cities = data.items
    },
    clearPharma() {
      this.form.pharmacy = ''
      this.getPharma()
    },
    async getPharma(query) {
      let params = {
        search: 'pharma' == query ? '' : query,
        city: this.form.city ? this.form.city.id : '',
        limit: 300
      }
      let { data } = (await PharmaResource.execute(params))
      this.pharmas = data.items
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      let data = JSON.parse(JSON.stringify(this.form))
      this.form.first_name = ''
      this.form.last_name = ''
      data.first_name = ''
      data.last_name = ''
      data.pharmacy = pathOr('', ['id'], data.pharmacy)
      data.city = pathOr('', ['id'], data.city)
      let name = data.name.split(' ')
      data.first_name = name[0]
      data.last_name = name[1] || ''
      this.submit(valid, data)
    },
    send(data) {
      return userInfoUpdate.execute({}, data)
        .then((res) => {
          AnswerMessage(res.meta, this)
          this.$emit('close')
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
  }
}
</script>