<template lang="pug">
  include ../../../mixins
  +b-context('d-control-input')
    +e.LABEL.label.--bound(
      :for='id', 
      :class="{'d-control-input__label--active': value }"
    ) {{ inputLabel }} 
      +e.is_required.SPAN(v-if='isRequired') *
</template>

<script>
export default {
  name: 'BoundLabel',
  props: {
    id: {
      type: String,
      required: false
    },
    value: {},
    inputLabel: {
      type: String
    },
    isRequired: {
      type: Boolean
    }
  }
}
</script>