<template lang="pug">
  include ../../mixins 
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    .relative
      +b.error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    +b.g-row
      +b.g-cell.g-cols--12.ns-space_mt--6 
        control-simple-input-v(
          :rules="'required|email'",
          :input-label='labels.email'
          v-model='form.email'
          id='email',
          ref='email',
          name='email',
        )  

    +b.g-row 
      +b.g-cell.g-cols--12.ns-space_mt--10 
        +b.ns-button.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _(' відновити пароль') }}
   


</template>
<script>
import { passwordRecoveryResource } from '@api/auth.service'
import { 
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'PasswordRecovery',
  mixins: [ FormMixin ],
  data() {
    return {
      labels: {
        email: this._('Email'),
      },
      form: {
        password: ''
      },
      isLoad: false,
      non_field_errors: ''
    }
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return passwordRecoveryResource.execute({}, data)
        .then((res) => {
          AnswerMessage(res.meta, this)
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  }
}
</script>