export default function analyticCartAdd(
    name, id, price, quantity, brand='', category=''
) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []
  dataLayer.push({
    'ecommerce': {
      'currencyCode': 'UAH',
      'add': {
        'products': [{
          'name': name,
          'id': id,
          'price': price,
          'brand': brand,
          'category': category,
          'quantity': quantity
        }]
      }
    },
    'event': 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
    'gtm-ee-event-non-interaction': 'False',
  })
  
  dataLayer.push({
    'event': 'add_to_cart',
    'value': price,
    'currency': 'UAH',
    'content_ids': [ id ],
    'content_name': name,
    'content_category': '',
    'items': [{
      'id': id,
      'google_business_vertical': 'retail',
    }],
    'contents': [ id ],
  })

}
