<template>
  <control-checker-select
    :value="value"
    multiple
    trackBy="id"
    label="caption"
    :placeholder="placeholder || filter.caption"
    :internal-search="false"
    :options="combinedOptions"
    @search-change="search"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:noResult>{{ _('No elements found. Consider changing the search query.') }}</template>
    <template v-slot:noOptions>{{ _('Type to search.') }}</template>
  </control-checker-select>
</template>

<script>

export default {
  name: 'filter-autocomplete-multiselect-widget-component',
  props: ['value', 'filter', 'receive', 'placeholder', 'options', 'parameters'],

  computed: {
    combinedOptions() {
      const value = (this.value || [])
      const optionKeys = this.options.map(x => x.id)

      return value.filter(x => !optionKeys.includes(x.id)).concat(this.options)
    },
  },

  methods: {
    search(q) {
      let limit = 3
      if (q < limit) {
        return
      }

      this.receive({ q, filters: this.parameters.values })
    },
  },
}

</script>
