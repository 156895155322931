<script>
import { confirmWithPromise } from '@utils/helpers'
import { mapActions } from 'vuex'
import analyticCartRemove from '@analytic/remove'
export default {
  name: 'CartProduct',
  props: {
    product: {
      default: () => { return {} }
    },
  },
  data() {
    return {
      load: false
    }
  },
  methods: {
    ...mapActions('cart', [
      'changeCart'
    ]),
    async updateCart(info, remove_event) {
      let changes = info.count - this.product.base.quantity
      if (!changes) return
      this.load = true
      let data = {
        element: {
          id: this.product.base.element.id, 
          type: this.product.base.element.type
        },
        quantity: changes
      }
      let entities = []
      entities.push(data)
      
      let product = {
        'name': this.product.base.element.props.title ,
        'id': this.product.base.element.id,
        'price': this.product.base.price,
        'brand': '',
        'category': '',
        'quantity': this.product.base.quantity
      }


      if (remove_event) analyticCartRemove([product])
      await this.changeCart({ entities: entities })
        .then(() => {
          this.load = false
        }, err => {
          this.load = false
          this.error = `${this._('Товар можна замовити лише у кількості')} ${this.product.base.element.props.quantity} ${this._('шт')}`
          this.$emit('setError', { item: this.product, error: this.error })
        })
    },
    async removeFromCart() {
      let question = this._('Вы действительно хотите удалить товар с корзины?')
      try {
        await confirmWithPromise(question, this)
        let count = -1
        let obj = {
          count: count
        }
        
        this.updateCart(obj, true)
        
      } catch (e) {

      }

    }
  },

  render(h) {
    return this.$scopedSlots.default({
      removeFromCart: () => this.removeFromCart(),
      updateCart: (event) => this.updateCart(event),
      isProductload: this.load,
      error: this.error
    })
  }
}
</script>