<template lang='pug'>
  include ../../mixins
  controller.relative(
    :localsearch='search'
  )
    template(#inputview='{ keydownEvent, keyupEvent, model, goToSearch }')
      +b.g-row.relative.search-form
        +b.g-cell.g-cols--9
          +b.A.search-trigger.--fixed-width(href='', @click.prevent='activeSearch' )
            +e.loop.icon-search
          transition(name="fade")
            control-simple-input(
              type='search',
              :placeholder='_("Пошук")',
              v-model.trim='search',
              @keydown='keydownEvent'
              autocomplete='no'
              ref='search-input'
              @keyup="keyupEvent",
            )
        +b.g-cell.g-cols--3
          +b.ns-button--mini.A(
            href='',
            @click.prevent='goToSearch("")'
          )
            +e.title.P {{ _('Пошук') }}
</template>
<script>
import { mapState } from 'vuex'
import Controller from './Controller'
export default {
  name: 'SearchView',
  props: ['className', 'fieldId', 'url'],
  components: {
    Controller
  },
  watch: {
    global_search(nval) {
      this.search = nval
    }
  },
  
  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('search', [
      'global_search'
    ])
  },

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if ('search/SET_SEARCH' == mutation.type ) { 
        this.search = mutation.payload
      }
    })
  },
}

</script>
