export default function analyticImpressions(object_list) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []

  dataLayer.push({
    'ecommerce': {
      'currencyCode': 'UAH',
      'impressions': object_list
    },
    'event': 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Product Impressions',
    'gtm-ee-event-non-interaction': 'True',
  })

}


const threshold = 0.7
const options = {
  root: null,
  rootMargin: '0px',
  threshold,
}
export const analyticImpressionHandler = (selector) => {
  if (!window.is_analytic_enabled) return
  const targets = document.querySelectorAll(selector)

  function analyticScroll(targets, self) {
    let arr = []

    targets.forEach(entry => {
      if (entry.isIntersecting) {
        let { name, id, price } = entry.target.dataset
        let obj = {
          'name': name,
          'id': id,
          'price': price,
          'brand': '',
          'category': '',
        }
        arr.push(obj)
      }
    })
    if (arr.length) analyticImpressions(arr)
  }

  const observer = new IntersectionObserver(analyticScroll, options)
  const ar = [].slice.call(targets)
  
  
  ar.forEach(target => {
    observer.observe(target)
  })

}
