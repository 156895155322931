import { 
  retrieveCartEntity,  
  changeCartEntity,
  clearCartEntity,
  retrieveCartInfo,
} from '@api/cart.service'

const exclude_types = [ window.coupon ]

import pathOr from 'ramda/src/pathOr'

export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      cart: {
        groups: []
      },
      cart_info: {
        quantity: 0,
        total_price: 0
      },
    },
    actions: {
      async getCart({ commit }, payload) {
        let { data } = (await retrieveCartEntity.execute())
        commit('SET_CART', { payload: data })
      },
      async changeCart({ commit }, payload) {
        return changeCartEntity.execute({}, payload)
          .then(async ({ data }) => {
            commit('SET_CART', { payload: data })
          })
      },
      async clearCart({ commit }, payload) {
        let { data } = (await clearCartEntity.execute())
        commit('SET_CART', { payload: data })
      },
      async getCartInfo({ commit }, payload) {
        let { data } = (await retrieveCartInfo.execute())
        commit('SET_CART_INFO', data )
      },
      setErrors({ commit }, payload) {
        commit('SET_ERRORS', payload)
      }
    },
    mutations: {
      SET_ERRORS(state, payload) {
        let product = state.cart.groups
          .findIndex(el => el.base.element.id == payload.item.base.element.id)
        state.cart.groups[product].error = payload.error
      },
      SET_CART(state, payload) {
        state.cart = payload.payload
        exclude_types.forEach(type => {
          state.cart[type] = []
          state.cart.groups.forEach((group, idx) => {
            group.error = ''
            let result = pathOr(null, ['base', 'element', 'type'], group)
            if (type == result) {
              state.cart[type].push(group)
              let one = 1
              state.cart.groups.splice(idx, one)
            }
          })
        })

        // state.cart_info.total_price = state.cart.total_price
        state.cart_info.quantity = state.cart.quantity
      },
      SET_CART_INFO(state, payload) {
        state.cart_info = payload
        window.cart_total = state.cart_info.total_price
      },
    }
  }
}

