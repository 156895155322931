<template lang="pug">
  include ../../mixins
  +b.ds-panel--space_lg.--space_10-xl.relative
    +b.close-popup.mdi.mdi-close.A(
      href='', 
      @click.prevent='$emit("close")'
    )
    +e.element--offset_full
      +b.ds-caption--size_lg.--medium.H2.main-title {{ _('Кошик') }}
      template(v-if='cart.quantity')
        cart-product(
          v-for='(product, idx) in cart.groups'
          :key='idx',
          :product='product',
          @update:cart='updateEntities($event)'
          @setError='setErrors($event)'
        )
          template(
            #default='{ removeFromCart, updateCart, isProductload, error, }'
          )
            cart-product-ui(
              :product='product',
              :load='isProductload'
              @removeFromCart='removeFromCart',
              @updateCart='updateCart',
            )
        row(
          justify='between'
        )
          cell(cols='12 4-xl')
            promocode
          cell(cols='12 8-xl')
            one-click-order(
              v-if='oneClickOrderEnabled'
            )
        
        +b.ns-space_mt--10.ROW(justify='end')
          cell(cols='12 4-xl') 
            +b.ds-caption--size_xs.--color_grey.--bold.P {{ _('Tоварів у кошику:') }} {{ cart.quantity }}
        +b.ns-space_mt--2.ROW(justify='end')
          cell(cols='12 4-xl')
            row(
              justify='between'
            )
              cell(cols='narrow')
                +b.ds-caption--size_md.--bold.P {{ _('Всього:') }} 
              cell(cols='narrow')
                +b.ds-caption--size_md.--bold.--color_accent.P  {{ cart.total_price }}
        +b.ns-space_mt--10.ROW(
          justify='between'
        )
          cell(cols='12 4-xl')
            +b.ns-button--light.--bordered.A(
              href='',
              @click.prevent='$emit("close")'
            )
              +e.title.P() {{ _(' продовжити покупки') }}
          cell(cols='12 6-md 4-xl')
            +b.ns-button.A(
              @click.prevent='initCheckout'
            )
              +e.title.P() {{ _('оформити замовлення') }}
      template(v-else)
        +b.ns-space_mt--10.ROW(
          justify='center',
          align='center',
          appearance='column'
        )
          cell(cols='12 5-xl')
            +b.ds-caption--size_lg.--medium.--appearance_center.H2 {{ _('Ваш кошик для покупок поки що порожній') }}
          +b.ns-space_mt--10.ns-space_mb--10.CELL(cols='12 5-xl')
            +b.ns-button.A(
              href='',
              @click.prevent='$emit("close")'
            )
              +e.title.P() {{ _(' продовжити покупки') }}

</template>

<script>
import { mapState, mapActions } from 'vuex'
import CartProduct from '@app/Cart/Product'
import Promocode from '@app/Cart/Promocode'
import OneClickOrder from '@app/Purchase/OneClickOrder'
import CartProductUi from '@app/Cart/ProductUi'
import analyticCheckout from '@analytic/checkout'
export default {
  name: 'CartView',
  components: {
    CartProduct,
    CartProductUi,
    Promocode,
    OneClickOrder,
  },
  data() {
    return {
      order_page: window.order_page,
      index_page: window.baseUrl,
      oneClickOrderEnabled: window.is_one_click_order_enabled
    }
  },
  async mounted() {
    await this.getCart()
    if (this.cart.quantity) {
     
    }
  },
  computed: {
    ...mapState('cart', [
      'cart'
    ])
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
      'setErrors'
    ]),
    initCheckout() {
      const step = 1
      let time = 200
      analyticCheckout(this.cart.groups, step)
      // analyticInitCheckout(this.cart.groups)
      setTimeout(() => {
        window.location.href = this.order_page
      }, time)
    },
  }
}
</script>