export default function analyticSearchResults(
    cart_total, search_string, products
) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []
  let ids = products.map(el => el.id)
  let items = ids.map(el => {
    return {
      id: el,
      'google_business_vertical': 'retail'
    }
  })
  dataLayer.push({
    'event': 'view_search_results',
    'value': cart_total,
    'currency': 'UAH',
    'search_string': search_string,
    'content_category': '',
    'content_ids': ids,
    'items': items,
    'contents': ids
  })

}
