import { 
  ValidationProvider, 
  ValidationObserver,
  
  localize 
} from 'vee-validate'

import Vue from 'vue'

import { DICTIONARY } from './dictionary'
import './rules'

// import 
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

localize(DICTIONARY)

//localize hack ! is not localize dublicate !
let LOCALE = window.language
localize(LOCALE)

