export const toggleWeight = {
  bind(el, binding, vnode) {
    el.addEventListener('click', (e) => {
      let parent = vnode.elm.closest(el.dataset.parent)
      let other_triggers = Array.from(parent.getElementsByClassName('variant-trigger'))
      other_triggers.forEach(trigger => {
        trigger.classList.remove('is-active')
      })
      el.classList.add('is-active')
      let weight = Array.from(parent.getElementsByClassName(`variant-view`))
      weight.forEach(w => {
        w.classList.remove('is-active')
        if (w.dataset.weight == binding.value) {
          w.classList.add('is-active')
          parent.dataset[w.dataset.key] = w.dataset.val
        }
      })
    })
  }
}

export default { toggleWeight }
