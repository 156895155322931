import { 
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'cart'

const CART_CHANGE_URL = prefixAPI('/change/', MODEL_PREFIX)
const CART_CLEAR_URL = prefixAPI('/clear/', MODEL_PREFIX)
const CART_RETRIEVE_URL = prefixAPI('/retrieve/', MODEL_PREFIX)
const CART_INFO_RETRIEVE_URL = prefixAPI('/quantity/', MODEL_PREFIX)
const CART_COUPON_URL = prefixAPI('/coupon/', MODEL_PREFIX)

export const changeCartEntity = createResource(CART_CHANGE_URL, postResource)
export const clearCartEntity = createResource(CART_CLEAR_URL, postResource)
export const addCartCoupon = createResource(CART_COUPON_URL, postResource)
export const retrieveCartEntity = createResource(CART_RETRIEVE_URL)
export const retrieveCartInfo = createResource(CART_INFO_RETRIEVE_URL)

