<template lang="pug">
  include ../../mixins
  div(v-if='list.length')
    +b.review-view(
      v-for='review in list'
      :key='review.id'
    )
      +e.title.P.ds-caption--size_xs.--bold.P {{ review.props.name }}
      +e.body
        +b.ds-panel--space_xl
          +e.element--offset_full
            +b.ds-caption--size_xs.P {{ review.props.comment }}
    ui-limit-pagination(
      :pagination='meta'
      @input='offset = $event.offset; getList()'
    )  
</template>

<script>

import {
  ReviewListResoure
} from '@api/vendor.service'

export default {
  name: 'ReviewList',
  props: {
    product_type: {
      type: String
    },
    product_id: {
      type: String
    }
  },
  data() {
    return {
      list: {},
      offset: 0,
      meta: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      let params = {
        type: this.product_type,
        id: this.product_id,
        offset: this.offset
      }
      let { data: { items, pagination } } = (await ReviewListResoure
        .execute(params))
      this.list = items
      this.meta = pagination
    }
  }
}
</script>