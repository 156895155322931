import { 
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const CITIES_LIST_URL = prefixAPI('/cities/list/{?search,limit}', 'danone')
const PHARMA_LIST_URL = prefixAPI('/pharmacies/list/{?search,city,limit}', 'danone')
const ORDER_CREATE_URL = prefixAPI('/create/', 'orders')
const ORDER_ONE_CLICK_URL = prefixAPI('/one-click/', 'orders')

export const CitiesResource = createResource(CITIES_LIST_URL)
export const PharmaResource = createResource(PHARMA_LIST_URL)
export const OrderCreate = createResource(ORDER_CREATE_URL, postResource)
export const OneClickOrderCreate = createResource(
  ORDER_ONE_CLICK_URL, postResource
)




