function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-accordion',{attrs:{"init":false,"full-height":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var activeState = ref.activeState;
var toggleActive = ref.toggleActive;
var collapse = ref.collapse;
var ref_style = ref.style;
var icon = ref_style.icon;
var myHeight = ref_style.myHeight;
var isDesktop = ref.isDesktop;
var rest = objectWithoutProperties( ref, ["activeState", "toggleActive", "collapse", "style", "isDesktop"] );
var other = rest;
return [_c('div',{staticClass:"ns-accordion",class:{"ns-accordion--is-open": activeState}},[_c('ui-click-outside',{attrs:{"do":collapse}},[_c('div',[(!isDesktop)?_c('div',{staticClass:"ns-accordion__question",on:{"click":function($event){return toggleActive()}}},[_c('i',{staticClass:"ns-accordion__icon mdi",class:icon}),_c('div',{staticClass:"ns-accordion__text"},[_c('p',{staticClass:"ds-caption ds-caption--size_sm ds-caption--size_xs-xl ds-caption--medium ds-caption--appearance_center"},[_vm._v(_vm._s(_vm._('Фільтри'))+" ")])])]):_vm._e(),_c('div',{staticClass:"ns-accordion__description",class:{"isFullMaxHeight": isDesktop},style:({maxHeight: myHeight})},[_c('div',{staticClass:"ns-accordion__content ns-accordion__content--no-pa"},[_c('filter-ui',{attrs:{"disabled":_vm.loading,"value":_vm.prepared,"available":_vm.availableMap,"filters":_vm.filters},on:{"input":_vm.handleInputFilter},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('cell',{attrs:{"cols":"12"}},[_c('div',{staticClass:"ds-panel ds-panel--space_xl"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_full"},[_c('a',{staticClass:"ns-button ns-space_mt ns-space_mt--5",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.handleInputFilter({})}}},[_c('p',{staticClass:"ns-button__title"},[_vm._v(" "+_vm._s(_vm._("Відмінити фільтри")))])])])])])]},proxy:true}],null,true)})],1)])])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }