<template>
  <control-select
    :value="filter.caption"
    :placeholder="placeholder || filter.caption"
    :searchable="false"
    variant="dropdown-wrapper"
    :size="$attrs.size"
    :options="[]"
  >
    <template v-slot:noOptions>
      <div>
        {{
          formatTranslation(
            _('От <[start]>(<[min]>) до <[end]>(<[max]>)'),
            { start: internal[0], end: internal[1], min, max }
          )
        }}
      </div>
      <vue-slider
        class="control-slider"
        v-model="internal"
        v-bind="$attrs"
        :clickable="false"
        :drag-on-click="true"
        transition="0.2"
        :min="min"
        :max="max"
        @drag-end="update"
      />
    </template>
  </control-select>
</template>

<script>

import { path } from 'ramda'
import { formatTranslation } from '@utils/strings'

const minPath = path(['props', 'min'])
const maxPath = path(['props', 'max'])
const one = 1
const zero = 0
export default {
  name: 'filter-slider-widget',
  props: ['value', 'filter', 'available', 'placeholder'],
  data() {
    return {
      internal: [zero, one],
      min: 0,
      max: 1,
    }
  },

  watch: {
    filter: { deep: true, immediate: true, handler: 'clearMinMax' },
    available: { deep: true, handler: 'clearMinMax' },
    value: { immediate: true, handler: 'updateInternal' },
  },

  methods: {
    formatTranslation,

    clearMinMax() {
      const zero = 0
      this.min = Math.max(
        minPath(this.available) || zero, minPath(this.filter) || zero)
      this.max = Math.min(
        maxPath(this.available) || zero, maxPath(this.filter) || zero)

      this.updateInternal()
    },

    updateInternal(value = this.value) {
      this.internal = this.normalize(value)
    },

    update() {
      this.input(this.internal)
    },

    input(value) {
      this.$emit('input', this.clear(value))
    },

    clear(value) {
      let [min, max] = value
        [min, max] = this.normalize({ min, max })
      if (min === this.min && max === this.max) {
        return undefined
      }

      const result = {}

      if (min !== this.min) {
        result.min = min
      }

      if (max !== this.max) {
        result.max = max
      }

      return result
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max]
      }

      const { min = this.min, max = this.max } = value

      return [Math.max(this.min, min), Math.min(this.max, max)]
    },
  },
}

</script>
