import $ from 'jquery'
window.$ = window.jQuery = $

import './public-path'
import 'babel-polyfill'
import Vue from 'vue'
import I18n from 'vue-i18n'
import { Store } from 'vuex'

import 'es6-promise/auto'

import { createApp } from './app'
import { plugin } from './install'
import './validation'

import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(plugin)
const { app } = createApp({
  Vue, I18n, Store,
})

app.$mount('#app')

// function flatten(list) {
//   let reducer = (acc, obj) => {
//     try {
//       obj.forEach(el => {
//         reducer(acc, el)
//       })
//     } catch(e) {
//       acc.push(obj)
//     }
//   }
//   let res = list.reduce((acc, obj) => {
//     reducer(acc, obj)
//     return acc
//   }, [])
//   return res
// }
// flatten([1, 'any [complex] string', null, function() {}, [1, 2, [3, '4'], 0], [], { a: 1 }]);

// function mobileRemote(text) {
//   let matrix = [
//     ['1',     '2abc',  '3def'],
//     ['4ghi',  '5jkl',  '6mno'],
//     ['7pqrs', '8tuv',  '9wxyz'],
//     ['*',     '0',     '#']
//   ]
//   let arr = Array.from(text)
//   let current = matrix[0][0]
//   arr.forEach(l => {
//     let start = matrix.find(e => {
//       let t = e.findIndex(a => {
//         let res = Array.from(a).findIndex(d => d == l)
//         return res >= 0 ? res : false
//       })
//       return t >= 0 ? t : false
      
//     })
//     console.log(start);
//   })
//   // Ваш код
// }
// mobileRemote('Cd')