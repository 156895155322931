<template lang="pug">
  include ../../mixins
  
  +b.control-stack.ns-space_mt--6.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
    autocomplete='off'
  )
    ui-loader(v-if='isLoad')
    row(
      space='none',
      v-if='cart[type].length'
    )
      cell(cols='10')
        control-simple-input-v.control-stack__element.control-stack__element--position_first(
          :rules="'required'",
          :is-disabled='true'
          :input-label='labels.promo_exist'
          v-model='cart[type][0].base.element.props.code'
          id='coupon',
          ref='coupon',
          name='coupon',
        )
      cell(cols='2')
        +b.ns-button--mini.d-control-button.control-stack__element.control-stack__element--position_last.BUTTON(
          type='submit',
          @click.prevent='removePromo()'
        )
          +e.title.P.mdi.mdi-close
    row(
      space='none',
      v-else
    )
      cell(cols='10')
        control-simple-input-v.control-stack__element.control-stack__element--position_first(
          :rules="'required'",
          :input-label='labels.promo'
          v-model='form.coupon'
          id='coupon',
          ref='coupon',
          name='coupon',
        )
      cell(cols='2')
        +b.ns-button--mini.d-control-button.control-stack__element.control-stack__element--position_last.BUTTON(
          type='submit',
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('OK') }}
</template>

<script>
import { addCartCoupon } from '@api/cart.service'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Promocode',
  mixins: [ FormMixin ],
  data() {
    return {
      form: {
        coupon: ''
      },
      isLoad: false,
      non_field_errors: '',
      type: window.coupon,
      coupon: '',
      labels: {
        promo_exist: this._('ИСПОЛЬЗОВАН ПРОМО-КОД:'),
        promo: this._('Введіть промокод (якщо є)')
      }

    }
  },
  computed: {
    ...mapState('cart', [
      'cart'
    ]),
  },
  
  methods: {
    ...mapMutations('cart', [
      'SET_CART'
    ]),
    ...mapActions('cart', [
      'changeCart'
    ]),
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return addCartCoupon.execute({}, data)
        .then(({ data }) => {
          this.SET_CART({payload: data})
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    removePromo() {
      this.coupon = this.cart[this.type][0]
      let removedCount = -999
      let data = {
        element: {
          id: this.coupon.base.element.id, 
          type: this.coupon.base.element.type
        },
        quantity: removedCount
      }
      let entities = []
      entities.push(data)
      this.changeCart({ entities: entities })
    }
  }
}
</script>