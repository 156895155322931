import AddToCart from '@app/Purchase/AddToCart'
import Counter from '@app/Purchase/Counter'
import CounterController from '@app/Purchase/CounterController'
import ProductAvailability from '@app/Purchase/ProductAvailability'

export default function install(Vue) {
  Vue.component(AddToCart.name, AddToCart)
  Vue.component(Counter.name, Counter)
  Vue.component(CounterController.name, CounterController)
  Vue.component(ProductAvailability.name, ProductAvailability)
}

