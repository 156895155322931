import { 
  createResource,
  deleteResource,
  postResource,
  patchResource,
} from '@resource/resource'
import {
  simpleTransformer
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const AUTH_LINK = prefixAPI('/login/', 'authentication')
const LOGOUT_LINK = prefixAPI('/logout/', 'authentication')
const REGISTER_LINK = prefixAPI('/register/', 'authentication')
const RECOVERY_LINK = prefixAPI('/password/reset/', 'authentication')
const RECOVERY_CONFIRM_LINK = prefixAPI('/password/reset/confirm/', 'authentication')
const USER_INFO_LINK = prefixAPI('/user/retrieve/', 'staff')
const USER_INFO_UPDATE_LINK = prefixAPI('/user/update/', 'staff')
const USER_ADDRESS_DELETE_LINK = prefixAPI('address/destroy/{id}/')


export const userInfo = createResource(USER_INFO_LINK)

export const userInfoUpdate = createResource(
  USER_INFO_UPDATE_LINK, patchResource
)
export const userAddressDelete = createResource(
  USER_ADDRESS_DELETE_LINK, deleteResource, ({})
)
export const loginResource = createResource(AUTH_LINK, postResource)
export const registerResource = createResource(REGISTER_LINK, postResource)
export const logoutResource = createResource(
  LOGOUT_LINK, postResource, simpleTransformer
)
export const passwordRecoveryResource = createResource(
  RECOVERY_LINK, postResource
)
export const passwordRecoveryConfirmResource = createResource(
  RECOVERY_CONFIRM_LINK, postResource
)

