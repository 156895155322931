<template>
  <control-date-picker
    :value="internal"
    @input="handleInput"
    v-bind="$attrs"
    :placeholder="filter.caption"
    :disabledDate="disabledDate"
    @close="update"
    range
  />
</template>

<script>

import { path } from 'ramda'

const startsPath = path(['props', 'starts'])
const endsPath = path(['props', 'ends'])
const MINUTES = 60
const HOURS = 24
const MILISEC = 1000
const DAY = MINUTES * MINUTES * HOURS * MILISEC

export default {
  name: 'filter-date-picker-widget',
  props: ['value', 'filter', 'available'],

  data() {
    return {
      changed: false,
      internal: [],
    }
  },

  computed: {
    starts() {
      const value = startsPath(this.available)

      return value && new Date(value) || null
    },
    ends() {
      const value = endsPath(this.available)

      return value && new Date(value) || null
    },
    disabledDate() {
      return date => (
        null === this.starts ||
        null === this.ends ||
        this.starts > date ||
        date > this.ends
      )
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.internal = this.normalize(value)
      },
    },
  },

  methods: {
    update() {
      if (this.changed) {
        this.changed = false
        this.input(this.internal)
      }
    },

    input(value) {
      this.$emit('input', this.clear(value))
    },

    clear(value) {
      let [starts, ends] = value
      if (null === starts || null === ends) {
        return undefined
      }
      const one = 1
      ends.setTime(ends.getTime() + DAY - one)
        [starts, ends] = this.normalize({ starts, ends })

      if (starts === this.starts && ends === this.ends) {
        return undefined
      }

      return { starts: starts.toISOString(), ends: ends.toISOString() }
    },

    handleInput(value) {
      this.changed = true
      this.internal = value
    },

    normalize(value) {
      if (!value) {
        return []
      }

      const { starts, ends } = value
      const fit = v => Math.max(this.starts, Math.min(this.ends, v))

      return [
        new Date(fit(new Date(starts))),
        new Date(fit(new Date(ends))),
      ]
    },
  },
}

</script>
