<template lang="pug">
  include ../../../mixins 
  +b.d-control-input
    +e.element.COMPONENT(
      :is="component",
      :disabled='isDisabled'
      :value='value',
      ref='input'
      v-on="listeners"
      v-bind='[$props, $attrs]'
    )
    component(
      :is='labelComponent',
      :id='id',
      :value='value',
      :input-label='inputLabel',
      :is-required='isRequired'
    )
    +b.change-type.mdi.A(
      href='',
      v-if='type == "password" '
      @click.prevent='changeType()',
      :class='show ? "mdi-eye-off" : "mdi-eye"'
    )
    +b.close-btn.A(
      v-if='$attrs.innerClearBtn && value'
      @click.prevent='clear',

    ) &times;
    component(
      :is="errorComponent", 
      :classes='errorClasses'
      :error='error'
    )          
</template>
<script>
export default {
  name: 'UnifiedControlDescriptor',
  props: {
    component: {
      
    },
    labelComponent: {},
    errorComponent: {
    },
    id: {},
    inputLabel: {},
    isRequired: {},
    isDisabled: {
      type: Boolean,
      default: false
    },
    error: {},
    value: {},
    type: {},
    errorClasses: {}
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: $event => {this.$emit('input', $event ? $event.id ? $event : ($event.type ? $event.target[this.getKey()] : $event) : $event )},
      }
    }  
  },

  mounted() {
    
  },
  methods: {
    getKey() {
      return 'checkbox' === this.$attrs.type ? 'checked' : 'value'
    },
    clear() {
      // this.value = ''
      this.$emit('input', '')
    },
    changeType() {
      this.show = !this.show 
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    }
  }
  
}
</script>