import { evolve, map, pipe } from 'ramda'
import { isEmpty } from '@aspectus/vue-utils'
import {
  keyedTransformer as k,
  sameTransformer,
  composeTransformers,
} from '@utils/transformers'
import { parametersMiddleware } from '@aspectus/resource'

const checkEmpty = value => (isEmpty(value) ? undefined : value)
const ar = value => (Array.isArray(value) ? value : [value])
// const selectTo = curry((filter, id) => 
//  filter.props.items.find(({ id }) => id === id))
const selectFrom = ({ id }) => id
const multiselectFrom = pipe(ar, map(selectFrom))
const dateFrom = date => new Date(date).toISOString()
export const dateRangeFrom = evolve({ starts: dateFrom, ends: dateFrom })
const combinedInputFrom = map(composeTransformers(
  k(selectFrom, 'element', 'id'),
  k(sameTransformer, 'search')
))

// const creators = {
//   select: (filters, key) => (filters[key]
//     ? k(map(pipe(selectTo(filters[key]), ar)), key)
//     : null
//   ),
// }

// export const createToTransformers = 
// filters => composeTransformers.apply(null, [
//   creators.select('sex'),
// ].filter(x => x !== null))

const simplifiers = {
  select: key => k(pipe(selectFrom, checkEmpty), key),
  multiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  intRange: key => k(pipe(sameTransformer, checkEmpty), key),
  autocompleteMultiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  combinedInput: key => k(pipe(combinedInputFrom, checkEmpty), key),
}

export const filtersToAPITransformer = composeTransformers(
  simplifiers.multiselect('language'),
  simplifiers.select('media_type'),
  simplifiers.select('website_type'),
  simplifiers.select('gender'),
  simplifiers.select('ordering'),

  simplifiers.intRange('age'),
  simplifiers.intRange('daily_likes'),
  simplifiers.intRange('total_likes'),
  simplifiers.intRange('daily_shares'),
  simplifiers.intRange('total_shares'),


  simplifiers.autocompleteMultiselect('countries'),
  simplifiers.autocompleteMultiselect('button'),
  simplifiers.autocompleteMultiselect('technologies'),

  simplifiers.combinedInput('author'),
  simplifiers.combinedInput('text')
)

export const filterAPIMiddleware = parametersMiddleware(parameters => ({
  ...parameters,
  filters: JSON.stringify(filtersToAPITransformer(parameters.filters)),
}))


export function friendlyUrlParser() {
  let path = window.location.pathname
  let zero = 0
  let parsedUrl = {}
  if (path.indexOf('filters') > zero) { 
    path = path.split('/page')[0]
    let hashes = path.split('filters/')[1].split(';')
    hashes.map(hash => {
      let [key, val] = hash.split('=')
      
      parsedUrl[key] = []
      if (val.indexOf(',') > zero) {
        val = val.split(',')
        val.forEach(v => {
          if (v.indexOf('/') > zero ) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(v))
          }
        })
      } else {
        if (val.indexOf('/') > zero ) {
          parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
        } else {
          parsedUrl[key].push(decodeURIComponent(val))
        }
      }
    })
  }
  return parsedUrl  
}

