<template>
  <control-checker-select
    :multiple="multiple"
    :value="internal"
    @input="input"
    @close="close"
    @open="open"
    trackBy="id"
    label="caption"
    :placeholder="placeholder || filter.caption"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script>

import { normalizeSelectValue } from '@utils/transformers'
import OpenableMixin from './OpenableMixin'

export default {
  name: 'filter-select-widget',
  props: ['value', 'filter', 'available', 'multiple', 'placeholder'],
  mixins: [OpenableMixin],

  computed: {
    selected() {
      return normalizeSelectValue(this.value).map(({ id }) => id)
    },
    options() {
      const available = this.available && 
        this.available.props && this.available.props.id || []
      const selected = available.concat(this.selected)

      return this.filter.props.items
        .filter(({ id }) => selected.includes(id))
    },
  },
}

</script>
