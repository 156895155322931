<template lang="pug">
  include ../../mixins
  div
    +b.seo-text.t-content(
      :style="{ maxHeight: `${contentHeight}px` }",
      ref="text"
    )
      slot
    +b.BUTTON.show-seo(
      type="button",
      v-if="showBtn"
      @click="showSeo = !showSeo; setHeight();"
      :class="{ 'visible': !showSeo }"
    ) {{ showSeo ? _('Сховати') : _('Показати') }}
</template>

<script>
export default {
  name: "SeoText",
  data() {
    return {
      showBtn: false,
      showSeo: false,
      contentHeight: this.visibleHeight,
      scrollHeight: 0
    }
  },
  props: {
    visibleHeight: 0,
  },
  mounted() {
    this.innerHeight()
  },
  methods: {
    innerHeight() {
      let delay = 500
      setTimeout(() => {
        if (this.$refs.text.scrollHeight > this.visibleHeight) {
          this.scrollHeight = this.$refs.text.scrollHeight
          this.$refs.text.style.maxHeight = `${this.visibleHeight}px`
          this.showBtn = true
        }
      }, delay)
    },
    setHeight() {
      if (this.showSeo) {
        this.contentHeight = this.scrollHeight
        return
      }
      this.contentHeight = this.visibleHeight
    }
  }
}
</script>