import { renderSlim, resolveInjection } from '@aspectus/vue-utils'


const defaultName = Symbol('drawer-element')

export default {
  name: 'drawer-receiver',

  functional: true,
  props: {
    name: {
      default: defaultName,
    }
  },

  render(h, context) {
    const drawer = resolveInjection(context.parent, context.props.name)
    const nodes = context.scopedSlots.default(drawer.parameters)

    return renderSlim(nodes, h, 'tag')
  },
}
