<template lang="pug">
  include ../../mixins
  +b.control-stack.ns-space_mt--6.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.g-row--align_center.--justify_between.--justify_end-xl.ns-space_mt--6
      cell(
        cols='narrow'
      )
        control-simple-input-v(
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.customer_phone'
          id='customer_phone',
          ref='customer_phone',
          name='customer_phone',
        )
      cell(
        cols='narrow'
      )
        +b.ns-link--appearance_underline.--accent.ds-caption--size_xs.A(
          href=''
          :disabled='isLoad'
          @click.prevent='prepareData(valid)'
        ) {{ _('Замовити в один клік') }}
</template>

<script>
import {
  OneClickOrderCreate
} from '@api/order.service'
import { 
  AnswerMessage,
} from '@utils/submit'
import { masking } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import { mapActions } from 'vuex'
export default {
  name: 'OneClickOrder',
  mixins: [ FormMixin ],
  data() {
    return {
      form: {
        customer_phone: '',
      },
      isLoad: false,
      labels: {
        phone: this._('Телефон'),
      },
    }
  },
  mounted() {
    masking('customer_phone', this)
  },
  methods: {
    ...mapActions('cart', [
      'getCart'
    ]),
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return OneClickOrderCreate.execute({}, data)
        .then((res) => {
          AnswerMessage(res.meta, this)
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  }
  
}
</script>