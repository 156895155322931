<template lang="pug">
  include ../../mixins
  +b.ds-panel--space_lg.--space_1-xl.relative
    +e.element--offset_full
      +b.g-row
        +b.g-cell.g-cols--12
          +b.ds-caption--size_lg.--medium.H2.main-title {{ question }}
        +b.g-cell.g-cols--6.ns-space_mt--6 
          +b.ns-button.A(
            @click.prevent='submitForm(true)'
          )
            +e.icon.SPAN.mdi.mdi-checkbox-marked-outline
            +e.title.P {{ _('Yes') }}
        +b.g-cell.g-cols--6.ns-space_mt--6 
          +b.ns-button.A(
            @click.prevent='submitForm(false)'
          )
            +e.title.P {{ _("No") }}
</template>
<script>
export default {
  name: 'Confirm',
  props: [ 'promise', "question"],
  data() {
    return {
      
    }
  },
  mounted() {
  },
  methods: {
    submitForm(value) {
      this.promise(value)
      this.$emit('close')
    },
  },
}
</script>
