<template lang="pug">
  include ../../../mixins
  +b.error-label.SPAN(
    v-show="error",
    :class='classes'
  ) {{ error }}
</template>
<script>
export default {
  name: 'ErrorComponent',
  props: {
    error: {
      type: String
    },
    classes: {}
  },
  
}
</script>