
/**
 * get cookie
 * @param {*} name 
 */
export function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        "\\$1"
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}


/**
 * set cookie without expired date
 * @param {*} name 
 * @param {*} val 
 */
export function setCookie(name, val) {
  // if (this.getCookie(name) ) return
  
  let bake_cookie = (name, value) => {
    let cookie = [name, '=', JSON.stringify(value), '; path=/;'].join('')
    document.cookie = cookie
  }
  bake_cookie(name, val)
}
