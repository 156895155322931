import UiMessageModal from '@app/UiElements/UiMessageModal'

export const AnswerMessage = (meta, $this) => {
  if (meta.message.header || meta.message.text) {
    $this.$modal.show(
      UiMessageModal,
      {
        meta: meta
      }, {
        height: 'auto',
        minWidth: 300,
        maxWidth: 370,
        adaptive: true
      }
    )
  } else {
    if (meta.location) {
      if (meta.location.length) { 
        window.location.pathname = meta.location
      }
    }
  }
}

