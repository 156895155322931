<template lang='pug'>
  row
    cell(
      cols="12 3-xl"
    )
      catalog-sidebar(
        space="xl"
        :filters="filters"
        @update:filters="changeFilters"
        :pagination="pagination"
      )

    cell(
      cols="12 9-xl"
    )
      catalog-content(
        :loading="loading"
        :items="result && result.data"
        :pagination="pagination"
        @update:pagination="changePagination"
      )
        slot
</template>

<script>

import CatalogSidebar from './Sidebar'
import CatalogContent from './Content'

export default {
  components: { CatalogSidebar, CatalogContent },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
  ],

  created() {
    // this.search()
  },

  methods: {
    search() {
      this.receive(this.parameters)
    },
  },
}

</script>
