import * as rules from 'vee-validate/dist/rules'
import {
  extend, 
} from 'vee-validate'

// loop over all rules
for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule])
}
extend('password', {
  validate: (value, { other }) => { value === other },
  message: 'The password confirmation does not match.',
  params: [{ name: 'other', isTarget: true }]
})

extend('phone', {
  validate(value) {
    // Regex for a international phone number 
    const MOBILEREG = /^[+()\d-\s]+$/
    return MOBILEREG.test(value)
  },
})
