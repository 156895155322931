import VueI18n from 'vue-i18n'
import Vuex from 'vuex'

import VModal from 'vue-js-modal'
import { install } from '@app/index'
import '@utils'

import directives from '@utils/directives'
import * as VueGoogleMaps from 'vue2-google-maps'

export function plugin(Vue) {
  
  Vue.mixin({
    directives,
  })
  Vue.use(VueGoogleMaps, {
    load: {
      key: window.g_key,
      libraries: 'places'
  
    },
  })
  Vue.use(VueI18n)
  Vue.use(Vuex)
  Vue.use(install)
  Vue.use(VModal, { dynamic: true })
}

export default { plugin }

